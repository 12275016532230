/*
 * Tipped - A Complete Javascript Tooltip Solution - v4.6.0
 * (c) 2012-2017 Nick Stakenburg
 *
 * http://www.tippedjs.com
 *
 * License: http://www.tippedjs.com/license
 */
/* line 10, app/library/styles/less/tipped.less */
.tpd-tooltip {
  position: absolute;
}
/* Fix for CSS frameworks that don't keep the use of box-sizing: border-box
   within their own namespace */
/* line 16, app/library/styles/less/tipped.less */
.tpd-tooltip {
  box-sizing: content-box;
}
/* line 17, app/library/styles/less/tipped.less */
.tpd-tooltip [class^="tpd-"] {
  box-sizing: inherit;
}
/* Content */
/* line 20, app/library/styles/less/tipped.less */
.tpd-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  float: left;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/* line 29, app/library/styles/less/tipped.less */
.tpd-content-spacer,
.tpd-content-relative,
.tpd-content-relative-padder {
  float: left;
  position: relative;
}
/* line 35, app/library/styles/less/tipped.less */
.tpd-content-relative {
  width: 100%;
}
/* line 39, app/library/styles/less/tipped.less */
.tpd-content {
  float: left;
  clear: both;
  position: relative;
  padding: 10px;
  font-size: 11px;
  line-height: 16px;
  color: #fff;
  box-sizing: border-box !important;
}
/* line 49, app/library/styles/less/tipped.less */
.tpd-has-inner-close .tpd-content-relative .tpd-content {
  padding-right: 0 !important;
}
/* line 50, app/library/styles/less/tipped.less */
.tpd-tooltip .tpd-content-no-padding {
  padding: 0 !important;
}
/* line 52, app/library/styles/less/tipped.less */
.tpd-title-wrapper {
  float: left;
  position: relative;
  overflow: hidden;
}
/* line 57, app/library/styles/less/tipped.less */
.tpd-title-spacer {
  float: left;
}
/* line 60, app/library/styles/less/tipped.less */
.tpd-title-relative,
.tpd-title-relative-padder {
  float: left;
  position: relative;
}
/* line 65, app/library/styles/less/tipped.less */
.tpd-title-relative {
  width: 100%;
}
/* line 66, app/library/styles/less/tipped.less */
.tpd-title {
  float: left;
  position: relative;
  font-size: 11px;
  line-height: 16px;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  box-sizing: border-box !important;
}
/* line 77, app/library/styles/less/tipped.less */
.tpd-has-title-close .tpd-title {
  padding-right: 0 !important;
}
/* line 78, app/library/styles/less/tipped.less */
.tpd-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 28px;
  cursor: pointer;
  overflow: hidden;
  color: #fff;
}
/* line 88, app/library/styles/less/tipped.less */
.tpd-close-icon {
  float: left;
  font-family: Arial, Baskerville, monospace;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  width: 28px;
  height: 28px;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
}
/* Skin */
/* line 103, app/library/styles/less/tipped.less */
.tpd-skin {
  position: absolute;
  top: 0;
  left: 0;
}
/* line 109, app/library/styles/less/tipped.less */
.tpd-frames {
  position: absolute;
  top: 0;
  left: 0;
}
/* line 114, app/library/styles/less/tipped.less */
.tpd-frames .tpd-frame {
  float: left;
  width: 100%;
  height: 100%;
  clear: both;
  display: none;
}
/* line 122, app/library/styles/less/tipped.less */
.tpd-visible-frame-top .tpd-frame-top {
  display: block;
}
/* line 123, app/library/styles/less/tipped.less */
.tpd-visible-frame-bottom .tpd-frame-bottom {
  display: block;
}
/* line 124, app/library/styles/less/tipped.less */
.tpd-visible-frame-left .tpd-frame-left {
  display: block;
}
/* line 125, app/library/styles/less/tipped.less */
.tpd-visible-frame-right .tpd-frame-right {
  display: block;
}
/* line 127, app/library/styles/less/tipped.less */
.tpd-backgrounds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}
/* line 136, app/library/styles/less/tipped.less */
.tpd-background-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  pointer-events: none;
}
/* line 145, app/library/styles/less/tipped.less */
.tpd-no-shadow .tpd-skin .tpd-background-shadow {
  box-shadow: none !important;
}
/* line 147, app/library/styles/less/tipped.less */
.tpd-background-box {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
/* only the top background box should be shown when not using a stem */
/* line 156, app/library/styles/less/tipped.less */
.tpd-no-stem .tpd-background-box,
.tpd-no-stem .tpd-shift-stem {
  display: none;
}
/* line 158, app/library/styles/less/tipped.less */
.tpd-no-stem .tpd-background-box-top {
  display: block;
}
/* line 160, app/library/styles/less/tipped.less */
.tpd-background-box-shift,
.tpd-background-box-shift-further {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}
/* line 167, app/library/styles/less/tipped.less */
.tpd-background {
  border-radius: 10px;
  float: left;
  clear: both;
  background: none;
  -webkit-background-clip: padding-box;
  /* Safari */
  background-clip: padding-box;
  /* IE9+, Firefox 4+, Opera, Chrome */
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.1);
  /* opacity here bugs out in firefox, .tpd-background-content should have no opacity if this opacity is less than 1 */
}
/* line 178, app/library/styles/less/tipped.less */
.tpd-background-loading {
  display: none;
}
/* no radius */
/* line 180, app/library/styles/less/tipped.less */
.tpd-no-radius .tpd-skin .tpd-frames .tpd-frame .tpd-backgrounds .tpd-background {
  border-radius: 0;
}
/* line 181, app/library/styles/less/tipped.less */
.tpd-background-title {
  float: left;
  clear: both;
  width: 100%;
  background-color: #282828;
}
/* line 187, app/library/styles/less/tipped.less */
.tpd-background-content {
  float: left;
  clear: both;
  width: 100%;
  background-color: #282828;
}
/* line 193, app/library/styles/less/tipped.less */
.tpd-background-border-hack {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-style: solid;
}
/* line 202, app/library/styles/less/tipped.less */
.tpd-background-box-top {
  top: 0;
}
/* line 203, app/library/styles/less/tipped.less */
.tpd-background-box-bottom {
  bottom: 0;
}
/* line 204, app/library/styles/less/tipped.less */
.tpd-background-box-left {
  left: 0;
}
/* line 205, app/library/styles/less/tipped.less */
.tpd-background-box-right {
  right: 0;
}
/* Skin / Stems */
/* line 208, app/library/styles/less/tipped.less */
.tpd-shift-stem {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
/* line 214, app/library/styles/less/tipped.less */
.tpd-shift-stem-side {
  position: absolute;
}
/* line 217, app/library/styles/less/tipped.less */
.tpd-frame-top .tpd-shift-stem-side,
.tpd-frame-bottom .tpd-shift-stem-side {
  width: 100%;
}
/* line 219, app/library/styles/less/tipped.less */
.tpd-frame-left .tpd-shift-stem-side,
.tpd-frame-right .tpd-shift-stem-side {
  height: 100%;
}
/* line 222, app/library/styles/less/tipped.less */
.tpd-stem {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  /* shows possible invalid subpx rendering */
  width: 16px;
  /* best cross browser stem: width = 2 x height (90deg angle) */
  height: 8px;
  margin-left: 3px;
  /* space from the side */
  margin-top: 2px;
  /* space between target and stem */
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}
/* remove margins once we're done measuring */
/* line 235, app/library/styles/less/tipped.less */
.tpd-tooltip .tpd-skin .tpd-frames .tpd-frame .tpd-shift-stem .tpd-stem-reset {
  margin: 0 !important;
}
/* line 237, app/library/styles/less/tipped.less */
.tpd-stem-spacer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* line 244, app/library/styles/less/tipped.less */
.tpd-stem-reset .tpd-stem-spacer {
  margin-top: 0;
}
/* line 246, app/library/styles/less/tipped.less */
.tpd-stem-point {
  width: 100px;
  position: absolute;
  top: 0;
  left: 50%;
}
/* line 252, app/library/styles/less/tipped.less */
.tpd-stem-downscale,
.tpd-stem-transform {
  float: left;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  position: relative;
}
/* line 262, app/library/styles/less/tipped.less */
.tpd-stem-side {
  width: 50%;
  height: 100%;
  float: left;
  position: relative;
  overflow: hidden;
}
/* line 269, app/library/styles/less/tipped.less */
.tpd-stem-side-inversed {
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
/* line 273, app/library/styles/less/tipped.less */
.tpd-stem-triangle {
  width: 0;
  height: 0;
  border-bottom-style: solid;
  border-left-color: transparent;
  border-left-style: solid;
  position: absolute;
  top: 0;
  left: 0;
}
/* line 283, app/library/styles/less/tipped.less */
.tpd-stem-border {
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  background-color: #fff;
  /* will become transparent */
  border-right-color: #fff;
  border-right-style: solid;
  border-right-width: 0;
}
/* line 295, app/library/styles/less/tipped.less */
.tpd-stem-border-corner {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  border-right-style: solid;
  border-right-width: 0;
}
/* fixes rendering issue in IE */
/* line 305, app/library/styles/less/tipped.less */
.tpd-stem * {
  z-index: 0;
  zoom: 1;
}
/* used by IE < 9 */
/* line 308, app/library/styles/less/tipped.less */
.tpd-stem-border-center-offset,
.tpd-stem-border-center-offset-inverse {
  float: left;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
/* line 316, app/library/styles/less/tipped.less */
.tpd-stem-notransform {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
}
/* line 322, app/library/styles/less/tipped.less */
.tpd-stem-notransform .tpd-stem-border {
  height: 100%;
  position: relative;
  float: left;
  top: 0;
  left: 0;
  margin: 0;
}
/* line 330, app/library/styles/less/tipped.less */
.tpd-stem-notransform .tpd-stem-border-center {
  position: absolute;
}
/* line 333, app/library/styles/less/tipped.less */
.tpd-stem-notransform .tpd-stem-border-corner {
  background: #fff;
  border: 0;
  top: auto;
  left: auto;
}
/* line 339, app/library/styles/less/tipped.less */
.tpd-stem-notransform .tpd-stem-border-center,
.tpd-stem-notransform .tpd-stem-triangle {
  height: 0;
  border: 0;
  left: 50%;
}
/* transformations for left/right/bottom */
/* line 347, app/library/styles/less/tipped.less */
.tpd-stem-transform-left {
  -webkit-transform: rotate(-90deg) scale(-1, 1);
  transform: rotate(-90deg) scale(-1, 1);
}
/* line 351, app/library/styles/less/tipped.less */
.tpd-stem-transform-right {
  -webkit-transform: rotate(90deg) translate(0, -100%);
  transform: rotate(90deg) translate(0, -100%);
}
/* line 355, app/library/styles/less/tipped.less */
.tpd-stem-transform-bottom {
  -webkit-transform: scale(1, -1) translate(0, -100%);
  transform: scale(1, -1) translate(0, -100%);
}
/* Spinner */
/* line 362, app/library/styles/less/tipped.less */
.tpd-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 46px;
  height: 36px;
}
/* line 369, app/library/styles/less/tipped.less */
.tpd-spinner-spin {
  position: relative;
  float: left;
  margin: 8px 0 0 13px;
  text-indent: -9999em;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-left: 2px solid #fff;
  -webkit-animation: tpd-spinner-animation 1.1s infinite linear;
  animation: tpd-spinner-animation 1.1s infinite linear;
  box-sizing: border-box !important;
}
/* line 382, app/library/styles/less/tipped.less */
.tpd-spinner-spin,
.tpd-spinner-spin:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
@-webkit-keyframes tpd-spinner-animation {
  /* line 389, app/library/styles/less/tipped.less */
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  /* line 390, app/library/styles/less/tipped.less */
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes tpd-spinner-animation {
  /* line 393, app/library/styles/less/tipped.less */
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  /* line 394, app/library/styles/less/tipped.less */
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* show the loader while loading and hide all the content */
/* line 398, app/library/styles/less/tipped.less */
.tpd-is-loading .tpd-content-wrapper,
.tpd-is-loading .tpd-title-wrapper {
  display: none;
}
/* line 400, app/library/styles/less/tipped.less */
.tpd-is-loading .tpd-background {
  display: none;
}
/* line 401, app/library/styles/less/tipped.less */
.tpd-is-loading .tpd-background-loading {
  display: block;
}
/* Resets while measuring content */
/* line 406, app/library/styles/less/tipped.less */
.tpd-tooltip-measuring {
  top: 0;
  left: 0;
  position: absolute;
  max-width: 100%;
  width: 100%;
}
/* line 413, app/library/styles/less/tipped.less */
.tpd-tooltip-measuring .tpd-skin,
.tpd-tooltip-measuring .tpd-spinner {
  display: none;
}
/* line 418, app/library/styles/less/tipped.less */
.tpd-tooltip-measuring .tpd-content-wrapper,
.tpd-tooltip-measuring .tpd-title-wrapper {
  display: block;
}
/* Links */
/* line 423, app/library/styles/less/tipped.less */
.tpd-tooltip a,
.tpd-tooltip a:hover {
  color: #808080;
  text-decoration: underline;
}
/* line 425, app/library/styles/less/tipped.less */
.tpd-tooltip a:hover {
  color: #6c6c6c;
}
/*
 * Sizes
 */
/* x-small */
/* line 432, app/library/styles/less/tipped.less */
.tpd-size-x-small .tpd-content,
.tpd-size-x-small .tpd-title {
  padding: 7px 8px;
  font-size: 10px;
  line-height: 15px;
}
/* line 438, app/library/styles/less/tipped.less */
.tpd-size-x-small .tpd-background {
  border-radius: 5px;
}
/* line 439, app/library/styles/less/tipped.less */
.tpd-size-x-small .tpd-stem {
  width: 12px;
  height: 6px;
  margin-left: 4px;
  margin-top: 2px;
  /* space between target and stem */
}
/* line 445, app/library/styles/less/tipped.less */
.tpd-size-x-small.tpd-no-radius .tpd-stem {
  margin-left: 7px;
}
/* line 446, app/library/styles/less/tipped.less */
.tpd-size-x-small .tpd-close {
  margin-bottom: 1px;
}
/* line 447, app/library/styles/less/tipped.less */
.tpd-size-x-small .tpd-spinner {
  width: 35px;
  height: 29px;
}
/* line 451, app/library/styles/less/tipped.less */
.tpd-size-x-small .tpd-spinner-spin {
  margin: 6px 0 0 9px;
}
/* line 452, app/library/styles/less/tipped.less */
.tpd-size-x-small .tpd-spinner-spin,
.tpd-size-x-small .tpd-spinner-spin:after {
  width: 17px;
  height: 17px;
}
/* small */
/* line 459, app/library/styles/less/tipped.less */
.tpd-size-small .tpd-content,
.tpd-size-small .tpd-title {
  padding: 8px;
  font-size: 10px;
  line-height: 16px;
}
/* line 465, app/library/styles/less/tipped.less */
.tpd-size-small .tpd-background {
  border-radius: 6px;
}
/* line 466, app/library/styles/less/tipped.less */
.tpd-size-small .tpd-stem {
  width: 14px;
  height: 7px;
  margin-left: 5px;
  margin-top: 2px;
  /* space between target and stem */
}
/* line 472, app/library/styles/less/tipped.less */
.tpd-size-small.tpd-no-radius .tpd-stem {
  margin-left: 8px;
}
/* line 473, app/library/styles/less/tipped.less */
.tpd-size-small .tpd-close {
  margin: 2px 1px;
}
/* line 474, app/library/styles/less/tipped.less */
.tpd-size-small .tpd-spinner {
  width: 42px;
  height: 32px;
}
/* line 478, app/library/styles/less/tipped.less */
.tpd-size-small .tpd-spinner-spin {
  margin: 7px 0 0 13px;
}
/* line 479, app/library/styles/less/tipped.less */
.tpd-size-small .tpd-spinner-spin,
.tpd-size-small .tpd-spinner-spin:after {
  width: 18px;
  height: 18px;
}
/* medium (default) */
/* line 487, app/library/styles/less/tipped.less */
.tpd-size-medium .tpd-content,
.tpd-size-medium .tpd-title {
  padding: 10px;
  font-size: 11px;
  line-height: 16px;
}
/* line 493, app/library/styles/less/tipped.less */
.tpd-size-medium .tpd-background {
  border-radius: 8px;
}
/* line 494, app/library/styles/less/tipped.less */
.tpd-size-medium .tpd-stem {
  width: 16px;
  /* best cross browser stem width is 2xheight, for a 90deg angle */
  height: 8px;
  margin-left: 6px;
  /* space from the side */
  margin-top: 2px;
  /* space between target and stem */
}
/* line 500, app/library/styles/less/tipped.less */
.tpd-size-medium.tpd-no-radius .tpd-stem {
  margin-left: 10px;
}
/* line 501, app/library/styles/less/tipped.less */
.tpd-size-medium .tpd-close {
  margin: 4px 2px;
}
/* ideal spinner dimensions don't cause movement op top and
   on the stem when switching to text using position:'topleft' */
/* line 504, app/library/styles/less/tipped.less */
.tpd-size-medium .tpd-spinner {
  width: 50px;
  height: 36px;
}
/* line 508, app/library/styles/less/tipped.less */
.tpd-size-medium .tpd-spinner-spin {
  margin: 8px 0 0 15px;
}
/* line 509, app/library/styles/less/tipped.less */
.tpd-size-medium .tpd-spinner-spin,
.tpd-size-medium .tpd-spinner-spin:after {
  width: 20px;
  height: 20px;
}
/* large */
/* line 517, app/library/styles/less/tipped.less */
.tpd-size-large .tpd-content,
.tpd-size-large .tpd-title {
  padding: 10px;
  font-size: 13px;
  line-height: 18px;
}
/* line 523, app/library/styles/less/tipped.less */
.tpd-size-large .tpd-background {
  border-radius: 8px;
}
/* line 524, app/library/styles/less/tipped.less */
.tpd-size-large .tpd-stem {
  width: 18px;
  height: 9px;
  margin-left: 7px;
  margin-top: 2px;
  /* space between target and stem */
}
/* line 530, app/library/styles/less/tipped.less */
.tpd-size-large.tpd-no-radius .tpd-stem {
  margin-left: 10px;
}
/* line 531, app/library/styles/less/tipped.less */
.tpd-size-large .tpd-close {
  margin: 5px 2px 5px 2px;
}
/* line 532, app/library/styles/less/tipped.less */
.tpd-size-large .tpd-spinner {
  width: 54px;
  height: 38px;
}
/* line 536, app/library/styles/less/tipped.less */
.tpd-size-large .tpd-spinner-spin {
  margin: 9px 0 0 17px;
}
/* line 537, app/library/styles/less/tipped.less */
.tpd-size-large .tpd-spinner-spin,
.tpd-size-large .tpd-spinner-spin:after {
  width: 20px;
  height: 20px;
}
/* Skins */
/* default (dark) */
/* line 547, app/library/styles/less/tipped.less */
.tpd-skin-dark .tpd-content,
.tpd-skin-dark .tpd-title,
.tpd-skin-dark .tpd-close {
  color: #fff;
}
/* line 550, app/library/styles/less/tipped.less */
.tpd-skin-dark .tpd-background-content,
.tpd-skin-dark .tpd-background-title {
  background-color: #282828;
}
/* line 554, app/library/styles/less/tipped.less */
.tpd-skin-dark .tpd-background {
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.1);
}
/* line below the title */
/* line 559, app/library/styles/less/tipped.less */
.tpd-skin-dark .tpd-title-wrapper {
  border-bottom: 1px solid #404040;
}
/* spinner */
/* line 561, app/library/styles/less/tipped.less */
.tpd-skin-dark .tpd-spinner-spin {
  border-color: rgba(255, 255, 255, 0.2);
  border-left-color: #fff;
}
/* links */
/* line 566, app/library/styles/less/tipped.less */
.tpd-skin-dark a {
  color: #ccc;
}
/* line 567, app/library/styles/less/tipped.less */
.tpd-skin-dark a:hover {
  color: #c0c0c0;
}
/* light */
/* line 571, app/library/styles/less/tipped.less */
.tpd-skin-light .tpd-content,
.tpd-skin-light .tpd-title,
.tpd-skin-light .tpd-close {
  color: #333;
}
/* line 574, app/library/styles/less/tipped.less */
.tpd-skin-light .tpd-background-content {
  background-color: #fff;
}
/* line 577, app/library/styles/less/tipped.less */
.tpd-skin-light .tpd-background {
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}
/* line 581, app/library/styles/less/tipped.less */
.tpd-skin-light .tpd-background-title {
  background-color: #f7f7f7;
}
/* line 584, app/library/styles/less/tipped.less */
.tpd-skin-light .tpd-title-wrapper {
  border-bottom: 1px solid #c0c0c0;
}
/* line 585, app/library/styles/less/tipped.less */
.tpd-skin-light .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
/* fallback for no/disabled shadow */
/* line 587, app/library/styles/less/tipped.less */
.tpd-skin-light.tpd-no-shadow .tpd-background {
  border-color: rgba(100, 100, 100, 0.3);
}
/* line 590, app/library/styles/less/tipped.less */
.tpd-skin-light .tpd-spinner-spin {
  border-color: rgba(51, 51, 51, 0.2);
  border-left-color: #333;
}
/* line 594, app/library/styles/less/tipped.less */
.tpd-skin-light a {
  color: #808080;
}
/* line 595, app/library/styles/less/tipped.less */
.tpd-skin-light a:hover {
  color: #6c6c6c;
}
/* gray */
/* line 599, app/library/styles/less/tipped.less */
.tpd-skin-gray .tpd-content,
.tpd-skin-gray .tpd-title,
.tpd-skin-gray .tpd-close {
  color: #fff;
}
/* line 602, app/library/styles/less/tipped.less */
.tpd-skin-gray .tpd-background-content,
.tpd-skin-gray .tpd-background-title {
  background-color: #727272;
}
/* line 606, app/library/styles/less/tipped.less */
.tpd-skin-gray .tpd-background {
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.1);
}
/* line 610, app/library/styles/less/tipped.less */
.tpd-skin-gray .tpd-title-wrapper {
  border-bottom: 1px solid #505050;
}
/* line 611, app/library/styles/less/tipped.less */
.tpd-skin-gray .tpd-spinner-spin {
  border-color: rgba(255, 255, 255, 0.2);
  border-left-color: #fff;
}
/* line 615, app/library/styles/less/tipped.less */
.tpd-skin-gray a {
  color: #ccc;
}
/* line 616, app/library/styles/less/tipped.less */
.tpd-skin-gray a:hover {
  color: #b6b6b6;
}
/* red */
/* line 620, app/library/styles/less/tipped.less */
.tpd-skin-red .tpd-content,
.tpd-skin-red .tpd-title,
.tpd-skin-red .tpd-close {
  color: #fff;
}
/* line 623, app/library/styles/less/tipped.less */
.tpd-skin-red .tpd-background-content {
  background-color: #e13c37;
}
/* line 624, app/library/styles/less/tipped.less */
.tpd-skin-red .tpd-background {
  border-width: 1px;
  border-color: rgba(12, 0, 0, 0.6);
}
/* line 628, app/library/styles/less/tipped.less */
.tpd-skin-red .tpd-background-title {
  background-color: #e13c37;
}
/* line 629, app/library/styles/less/tipped.less */
.tpd-skin-red .tpd-title-wrapper {
  border-bottom: 1px solid #a30500;
}
/* line 630, app/library/styles/less/tipped.less */
.tpd-skin-red .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
/* line 631, app/library/styles/less/tipped.less */
.tpd-skin-red .tpd-spinner-spin {
  border-color: rgba(255, 255, 255, 0.2);
  border-left-color: #fff;
}
/* line 635, app/library/styles/less/tipped.less */
.tpd-skin-red a {
  color: #ddd;
}
/* line 636, app/library/styles/less/tipped.less */
.tpd-skin-red a:hover {
  color: #c6c6c6;
}
/* green */
/* line 640, app/library/styles/less/tipped.less */
.tpd-skin-green .tpd-content,
.tpd-skin-green .tpd-title,
.tpd-skin-green .tpd-close {
  color: #fff;
}
/* line 643, app/library/styles/less/tipped.less */
.tpd-skin-green .tpd-background-content {
  background-color: #4aab3a;
}
/* line 644, app/library/styles/less/tipped.less */
.tpd-skin-green .tpd-background {
  border-width: 1px;
  border-color: rgba(0, 12, 0, 0.6);
}
/* line 648, app/library/styles/less/tipped.less */
.tpd-skin-green .tpd-background-title {
  background-color: #4aab3a;
}
/* line 649, app/library/styles/less/tipped.less */
.tpd-skin-green .tpd-title-wrapper {
  border-bottom: 1px solid #127c00;
}
/* line 650, app/library/styles/less/tipped.less */
.tpd-skin-green .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
/* line 651, app/library/styles/less/tipped.less */
.tpd-skin-green .tpd-spinner-spin {
  border-color: rgba(255, 255, 255, 0.2);
  border-left-color: #fff;
}
/* line 655, app/library/styles/less/tipped.less */
.tpd-skin-green a {
  color: #ddd;
}
/* line 656, app/library/styles/less/tipped.less */
.tpd-skin-green a:hover {
  color: #c6c6c6;
}
/* blue */
/* line 660, app/library/styles/less/tipped.less */
.tpd-skin-blue .tpd-content,
.tpd-skin-blue .tpd-title,
.tpd-skin-blue .tpd-close {
  color: #fff;
}
/* line 663, app/library/styles/less/tipped.less */
.tpd-skin-blue .tpd-background-content {
  background-color: #45a3e3;
}
/* line 664, app/library/styles/less/tipped.less */
.tpd-skin-blue .tpd-background {
  border-width: 1px;
  border-color: rgba(0, 0, 12, 0.6);
}
/* line 668, app/library/styles/less/tipped.less */
.tpd-skin-blue .tpd-background-title {
  background-color: #45a3e3;
}
/* line 669, app/library/styles/less/tipped.less */
.tpd-skin-blue .tpd-title-wrapper {
  border-bottom: 1px solid #1674b4;
}
/* line 670, app/library/styles/less/tipped.less */
.tpd-skin-blue .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
/* line 671, app/library/styles/less/tipped.less */
.tpd-skin-blue .tpd-spinner-spin {
  border-color: rgba(255, 255, 255, 0.2);
  border-left-color: #fff;
}
/* line 675, app/library/styles/less/tipped.less */
.tpd-skin-blue a {
  color: #ddd;
}
/* line 676, app/library/styles/less/tipped.less */
.tpd-skin-blue a:hover {
  color: #c6c6c6;
}
/* lightyellow */
/* line 680, app/library/styles/less/tipped.less */
.tpd-skin-lightyellow .tpd-content,
.tpd-skin-lightyellow .tpd-title,
.tpd-skin-lightyellow .tpd-close {
  color: #333;
}
/* line 683, app/library/styles/less/tipped.less */
.tpd-skin-lightyellow .tpd-background-content {
  background-color: #ffffa9;
}
/* line 684, app/library/styles/less/tipped.less */
.tpd-skin-lightyellow .tpd-background {
  border-width: 1px;
  border-color: rgba(8, 8, 0, 0.35);
}
/* line 688, app/library/styles/less/tipped.less */
.tpd-skin-lightyellow .tpd-background-title {
  background-color: #ffffa9;
}
/* line 689, app/library/styles/less/tipped.less */
.tpd-skin-lightyellow .tpd-title-wrapper {
  border-bottom: 1px solid #a7a697;
}
/* line 690, app/library/styles/less/tipped.less */
.tpd-skin-lightyellow .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
/* line 691, app/library/styles/less/tipped.less */
.tpd-skin-lightyellow .tpd-spinner-spin {
  border-color: rgba(51, 51, 51, 0.2);
  border-left-color: #333;
}
/* line 695, app/library/styles/less/tipped.less */
.tpd-skin-lightyellow a {
  color: #777;
}
/* line 696, app/library/styles/less/tipped.less */
.tpd-skin-lightyellow a:hover {
  color: #868686;
}
/* lightblue */
/* line 700, app/library/styles/less/tipped.less */
.tpd-skin-lightblue .tpd-content,
.tpd-skin-lightblue .tpd-title,
.tpd-skin-lightblue .tpd-close {
  color: #333;
}
/* line 703, app/library/styles/less/tipped.less */
.tpd-skin-lightblue .tpd-background-content {
  background-color: #bce5ff;
}
/* line 704, app/library/styles/less/tipped.less */
.tpd-skin-lightblue .tpd-background {
  border-width: 1px;
  border-color: rgba(0, 0, 8, 0.35);
}
/* line 708, app/library/styles/less/tipped.less */
.tpd-skin-lightblue .tpd-background-title {
  background-color: #bce5ff;
}
/* line 709, app/library/styles/less/tipped.less */
.tpd-skin-lightblue .tpd-title-wrapper {
  border-bottom: 1px solid #909b9f;
}
/* line 710, app/library/styles/less/tipped.less */
.tpd-skin-lightblue .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
/* line 711, app/library/styles/less/tipped.less */
.tpd-skin-lightblue .tpd-spinner-spin {
  border-color: rgba(51, 51, 51, 0.2);
  border-left-color: #333;
}
/* line 715, app/library/styles/less/tipped.less */
.tpd-skin-lightblue a {
  color: #777;
}
/* line 716, app/library/styles/less/tipped.less */
.tpd-skin-lightblue a:hover {
  color: #868686;
}
/* lightpink */
/* line 720, app/library/styles/less/tipped.less */
.tpd-skin-lightpink .tpd-content,
.tpd-skin-lightpink .tpd-title,
.tpd-skin-lightpink .tpd-close {
  color: #333;
}
/* line 723, app/library/styles/less/tipped.less */
.tpd-skin-lightpink .tpd-background-content {
  background-color: #ffc4bf;
}
/* line 724, app/library/styles/less/tipped.less */
.tpd-skin-lightpink .tpd-background {
  border-width: 1px;
  border-color: rgba(8, 0, 0, 0.35);
}
/* line 728, app/library/styles/less/tipped.less */
.tpd-skin-lightpink .tpd-background-title {
  background-color: #ffc4bf;
}
/* line 729, app/library/styles/less/tipped.less */
.tpd-skin-lightpink .tpd-title-wrapper {
  border-bottom: 1px solid #a08f8f;
}
/* line 730, app/library/styles/less/tipped.less */
.tpd-skin-lightpink .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
/* line 731, app/library/styles/less/tipped.less */
.tpd-skin-lightpink .tpd-spinner-spin {
  border-color: rgba(51, 51, 51, 0.2);
  border-left-color: #333;
}
/* line 735, app/library/styles/less/tipped.less */
.tpd-skin-lightpink a {
  color: #777;
}
/* line 736, app/library/styles/less/tipped.less */
.tpd-skin-lightpink a:hover {
  color: #868686;
}
