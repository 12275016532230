/* standard values used across application */
/* palette of colours from style guide */
/* heights of position:fixed elements that we need to add spacer divs for for desktop screen width */
/* heights of position:fixed elements that we need to add spacer divs for for iPhone 6s screen width in landscape mode */
/* line 3, app/library/styles/less/arabic.less */
.sura-name {
  text-align: center;
  font-size: 18px;
  font-family: Cabin, Arial, Helvetica, sans-serif;
}
/* line 9, app/library/styles/less/arabic.less */
.aya {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 23.8px;
  direction: rtl;
  line-height: 180%;
}
/* line 22, app/library/styles/less/arabic.less */
.ayaSmaller {
  font-size: 12pt !important;
}
/* line 26, app/library/styles/less/arabic.less */
.ayaMedium {
  font-size: 18.2px !important;
}
/* line 30, app/library/styles/less/arabic.less */
.transcriptionMedium {
  font-size: 12.6px !important;
}
/* line 34, app/library/styles/less/arabic.less */
.ayaTranscription {
  line-height: 160%;
}
/* line 38, app/library/styles/less/arabic.less */
.ayaTranscriptionRegular {
  font-size: 15.4px;
}
/* line 42, app/library/styles/less/arabic.less */
.ayaTranscriptionSmaller {
  font-size: 10pt;
}
/* line 46, app/library/styles/less/arabic.less */
.ayaTranslation {
  line-height: 150%;
}
/* line 50, app/library/styles/less/arabic.less */
.ayaNum {
  color: green;
  font-size: smaller;
}
/* line 55, app/library/styles/less/arabic.less */
.right-to-left {
  text-align: right;
  direction: rtl;
}
/* line 61, app/library/styles/less/arabic.less */
.sign {
  font-family: times new roman;
  font-size: 0.9em;
  color: #fb7600;
}
/* line 67, app/library/styles/less/arabic.less */
.footer {
  text-align: center;
  margin: 20px 0px;
  color: #222;
  font-family: Arial;
  background-color: #f4f4ff;
  border: 1px solid #ccd;
  padding: 3px;
  font: 12px Verdana;
}
/* line 78, app/library/styles/less/arabic.less */
.scribal-change-word-underline {
  border-bottom: 3px red dotted;
}
/* line 82, app/library/styles/less/arabic.less */
.formulaic-highlight {
  color: blue;
}
/* line 87, app/library/styles/less/arabic.less */
.word-correction-list-arabic {
  font-size: 18pt;
}
/* line 92, app/library/styles/less/arabic.less */
.selected-dictionary-letter {
  color: #689862;
  font-weight: bold;
  padding-bottom: 2px;
  border-bottom: 2px #689862 solid;
}
/* line 100, app/library/styles/less/arabic.less */
.transliteration_formatting_preference {
  font-style: italic;
}
