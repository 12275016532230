/* standard values used across application */
/* palette of colours from style guide */
/* heights of position:fixed elements that we need to add spacer divs for for desktop screen width */
/* heights of position:fixed elements that we need to add spacer divs for for iPhone 6s screen width in landscape mode */
/* line 4, app/library/styles/less/flash.less */
#flash {
  border: 1px solid black;
  margin: 1em 0;
}
/* line 8, app/library/styles/less/flash.less */
#flash.alert {
  background-color: orange;
}
/* line 12, app/library/styles/less/flash.less */
#flash.notice {
  background-color: #689862;
}
/* line 16, app/library/styles/less/flash.less */
#flash p {
  margin: 0.5em;
}
/* line 20, app/library/styles/less/flash.less */
#flash .close-button {
  margin-right: 0.3em;
  float: right;
  cursor: pointer;
}
