/* standard values used across application */
/* palette of colours from style guide */
/* heights of position:fixed elements that we need to add spacer divs for for desktop screen width */
/* heights of position:fixed elements that we need to add spacer divs for for iPhone 6s screen width in landscape mode */
/* line 5, app/library/styles/less/charts.less */
.mini-mode .expander {
  float: right;
}
/* line 8, app/library/styles/less/charts.less */
.mini-mode .page-header {
  background-color: white;
  margin: 0px;
}
/* line 11, app/library/styles/less/charts.less */
.mini-mode .page-header h2 {
  padding: 0px;
}
/* line 17, app/library/styles/less/charts.less */
.normal-mode {
  min-width: 98vw;
}
/* line 21, app/library/styles/less/charts.less */
.table-controls,
.chart-controls {
  font-size: 15.4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
/* line 28, app/library/styles/less/charts.less */
.table-controls a,
.chart-controls a {
  padding: 8px;
  margin: 2px;
  background-color: #e6e1dc;
  color: #2e1f07;
  border-radius: 5px;
}
/* line 35, app/library/styles/less/charts.less */
.table-controls a:visited,
.chart-controls a:visited {
  color: black;
}
/* line 38, app/library/styles/less/charts.less */
.table-controls a.selected,
.chart-controls a.selected {
  /* background-color: #4c604c; // this is one of the greens from the re-skin */
  background-color: #689862;
  color: #fafcfa;
}
/* line 45, app/library/styles/less/charts.less */
.table-controls .flex-breaker,
.chart-controls .flex-breaker {
  width: 100%;
}
/* line 50, app/library/styles/less/charts.less */
.table-control,
.chart-control {
  margin: 12px 12px;
}
/* line 55, app/library/styles/less/charts.less */
.provenance-legend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  font-size: small;
  /* next line is a hack to align the legend with the scale on the chart for a desktop. Doesn't work for mobile */
  margin-left: 40px;
  /* next line will be superceded in re-skin */
  font-family: Arial, Helvetica, sans-serif;
}
/* line 66, app/library/styles/less/charts.less */
.provenance-legend .meccan {
  /* next line will probably change with the re-skin */
  color: #6060ff;
}
/* line 71, app/library/styles/less/charts.less */
.provenance-legend .medinan {
  /* next line will probably change with the re-skin */
  color: #ff9090;
}
/* line 76, app/library/styles/less/charts.less */
.provenance-legend .pre-transitional {
  /* next line will probably change with the re-skin */
  color: #008e00;
}
/* line 81, app/library/styles/less/charts.less */
.provenance-legend .post-transitional {
  /* next line will probably change with the re-skin */
  color: #ff0000;
}
/* line 86, app/library/styles/less/charts.less */
.provenance-legend .mixed-transitional {
  /* next line will probably change with the re-skin */
  color: #0000ff;
}
/* line 91, app/library/styles/less/charts.less */
.provenance-legend .provenance_mini {
  /* makes the provenance legend smaller in mini mode */
  font-size: smaller !important;
}
/* line 100, app/library/styles/less/charts.less */
.chart-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
/* line 112, app/library/styles/less/charts.less */
.formulaic-pick-table {
  border: 1px solid black;
  border-radius: 5px;
  padding: 8px;
  display: table;
  background-color: #e6e1dc;
}
@media print {
  /* line 121, app/library/styles/less/charts.less */
  .chart-controls {
    display: none;
  }
  /* line 125, app/library/styles/less/charts.less */
  .chart-control {
    border: 1px solid #2e1f07;
    border-radius: 5px;
    padding: 0.5em;
  }
  /* line 131, app/library/styles/less/charts.less */
  .chart-control .label:after {
    content: ":";
  }
  /* line 135, app/library/styles/less/charts.less */
  .table-controls a,
  .chart-controls a {
    background: none;
  }
  /* line 140, app/library/styles/less/charts.less */
  .table-controls a.selected,
  .chart-controls a.selected {
    font-weight: bold;
    color: black;
    background: none;
  }
}
