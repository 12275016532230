/* standard values used across application */
/* palette of colours from style guide */
/* heights of position:fixed elements that we need to add spacer divs for for desktop screen width */
/* heights of position:fixed elements that we need to add spacer divs for for iPhone 6s screen width in landscape mode */
/* qt */
/* line 5, app/library/styles/less/qt_tipped.less */
.tpd-skin-qt .tpd-content,
.tpd-skin-qt .tpd-title,
.tpd-skin-qt .tpd-close {
  color: #2e1f07;
}
/* line 12, app/library/styles/less/qt_tipped.less */
.tpd-skin-qt .tpd-content {
  background-color: #fafcfa;
}
/* line 19, app/library/styles/less/qt_tipped.less */
.tpd-skin-qt .tpd-background-content {
  background-color: #e6e1dc;
}
/* line 24, app/library/styles/less/qt_tipped.less */
.tpd-skin-qt .tpd-background {
  border-color: #2e1f07;
  border-width: 1px;
}
/* line 31, app/library/styles/less/qt_tipped.less */
.tpd-skin-qt .tpd-background-title {
  background-color: #e6e1dc;
}
/* line below the title */
/* line 40, app/library/styles/less/qt_tipped.less */
.tpd-skin-qt .tpd-title-wrapper {
  border-bottom: 1px solid #e6e1dc;
}
/* shadow */
/* line 49, app/library/styles/less/qt_tipped.less */
.tpd-skin-qt .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
/* spinner */
/* line 56, app/library/styles/less/qt_tipped.less */
.tpd-skin-qt .tpd-spinner-spin {
  border-color: #2e1f07;
  border-left-color: #fafcfa;
}
/* links */
/* line 67, app/library/styles/less/qt_tipped.less */
.tpd-skin-qt a {
  text-decoration: none;
}
/* line 72, app/library/styles/less/qt_tipped.less */
.tpd-skin-qt a:hover {
  color: #689862;
  text-decoration: none;
}
