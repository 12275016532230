/* standard values used across application */
/* palette of colours from style guide */
/* heights of position:fixed elements that we need to add spacer divs for for desktop screen width */
/* heights of position:fixed elements that we need to add spacer divs for for iPhone 6s screen width in landscape mode */
/* line 3, app/library/styles/less/common.less */
body,
button,
select,
input {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}
/* line 11, app/library/styles/less/common.less */
button {
  font-size: 15.4px;
  margin-left: 0.25em;
  margin-right: 0.25em;
  padding: 0.5em;
}
/* line 19, app/library/styles/less/common.less */
form {
  margin-bottom: 1em;
}
/* line 23, app/library/styles/less/common.less */
.page-header {
  padding: 0.5em;
}
/* line 28, app/library/styles/less/common.less */
.page-header h2 {
  font-family: Cabin, Arial, Helvetica, sans-serif;
  text-align: center;
  margin: 0px;
  padding: 0.5em 0px;
}
/* line 34, app/library/styles/less/common.less */
.page-header h3 {
  font-family: Cabin, Arial, Helvetica, sans-serif;
  text-align: center;
  margin-top: 0px;
}
/* line 39, app/library/styles/less/common.less */
.page-header a {
  text-decoration: none;
}
/* line 44, app/library/styles/less/common.less */
.page-controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
/* line 49, app/library/styles/less/common.less */
.page-controls a {
  padding: 8px;
  margin: 2px;
  background-color: #e6e1dc;
  color: black;
  border-radius: 5px;
}
/* line 56, app/library/styles/less/common.less */
.page-controls a:visited {
  color: black;
}
/* line 59, app/library/styles/less/common.less */
.page-controls a.selected {
  background-color: #689862;
  font-weight: bold;
}
/* line 63, app/library/styles/less/common.less */
.page-controls .flex-breaker {
  width: 100%;
}
/* line 68, app/library/styles/less/common.less */
.page-control {
  margin: 12px 12px;
}
/* line 72, app/library/styles/less/common.less */
.qt-icon {
  width: 16px;
  height: 16px;
  margin: 1px;
}
/* line 78, app/library/styles/less/common.less */
.small-margin-bottom-message {
  margin-top: 0.5em;
  margin-bottom: -0.5em;
}
/* line 84, app/library/styles/less/common.less */
.qt-big-logo-header {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  height: 120px;
  width: 120px;
  display: block;
}
/* line 94, app/library/styles/less/common.less */
.qt-mini-logo {
  height: 23px;
  width: 24px;
}
/* line 100, app/library/styles/less/common.less */
.page-title-text {
  font-family: Cabin, Arial, Helvetica, sans-serif;
  margin: 15px 0px 15px;
  text-align: center;
}
/* line 107, app/library/styles/less/common.less */
.override-cabin-font-for-subtitles-with-transcription {
  font-family: sans-serif;
}
/* line 112, app/library/styles/less/common.less */
.button-block-with-spacing {
  margin-bottom: 0.25em;
}
/* line 118, app/library/styles/less/common.less */
.pill-button {
  border: 2px solid #000090;
  border-radius: 15px;
  padding: 0px 0.5em !important;
  text-align: center;
  margin-right: 0px;
  margin-left: 0px;
  background-color: #000090;
  color: white;
}
/* line 130, app/library/styles/less/common.less */
.pill-tag {
  border: 2px solid #900000;
  border-radius: 15px;
  padding: 2px 0.5em;
  text-align: center;
  background-color: #900000;
  color: white;
  display: inline-block;
  margin-bottom: 4px;
  border: 0.5px solid #606060;
}
/* line 142, app/library/styles/less/common.less */
a.nodec {
  text-decoration: none;
  color: #2e1f07;
}
/* line 148, app/library/styles/less/common.less */
a.linky-parsed-word {
  text-decoration: none;
  color: #689862;
}
/* line 151, app/library/styles/less/common.less */
a.linky-parsed-word:hover {
  color: blue;
}
/* line 157, app/library/styles/less/common.less */
a.linky-dark {
  text-decoration: none;
  color: white;
}
/* line 160, app/library/styles/less/common.less */
a.linky-dark:hover {
  color: #4c604c;
}
/* line 165, app/library/styles/less/common.less */
a.linky-light {
  text-decoration: none;
  color: #689862;
}
/* line 168, app/library/styles/less/common.less */
a.linky-light:hover {
  color: #4c604c;
}
/* line 176, app/library/styles/less/common.less */
.linky-hover:hover {
  color: #689862;
  cursor: hand;
}
/* line 182, app/library/styles/less/common.less */
.linky-green {
  color: #4c604c;
  text-decoration: none;
}
/* line 185, app/library/styles/less/common.less */
.linky-green:hover {
  color: #689862;
  cursor: hand;
}
/* line 192, app/library/styles/less/common.less */
.linky-olive {
  color: black;
  text-decoration: none;
}
/* line 195, app/library/styles/less/common.less */
.linky-olive:hover {
  color: #e6e1dc;
  cursor: hand;
}
/* line 202, app/library/styles/less/common.less */
.linky {
  color: black;
  text-decoration: none;
}
/* line 176, app/library/styles/less/common.less */
.linky:hover {
  color: #689862;
  cursor: hand;
}
/* line 209, app/library/styles/less/common.less */
a.linky {
  color: black;
  text-decoration: none;
}
/* line 176, app/library/styles/less/common.less */
a.linky:hover {
  color: #689862;
  cursor: hand;
}
/* line 213, app/library/styles/less/common.less */
a.linky-no-hover {
  text-decoration: none;
  color: #2e1f07;
}
/* line 220, app/library/styles/less/common.less */
#verse-picker-container #sura-picker-container {
  width: 100%;
}
/* line 222, app/library/styles/less/common.less */
#verse-picker-container #sura-picker-container table {
  margin-top: -0.5em;
  width: 100%;
}
/* line 225, app/library/styles/less/common.less */
#verse-picker-container #sura-picker-container table .sura-picker-number {
  padding: 0.35em;
  text-align: center;
}
/* line 176, app/library/styles/less/common.less */
#verse-picker-container #sura-picker-container table .sura-picker-number:hover {
  color: #689862;
  cursor: hand;
}
/* line 230, app/library/styles/less/common.less */
#verse-picker-container #sura-picker-container table .sura-picker-number.selected {
  background-color: #e6e1dc;
}
/* line 235, app/library/styles/less/common.less */
#verse-picker-container #aya-picker-container {
  display: none;
  margin: -1em;
  margin-top: 0.5em;
  padding-bottom: 0.5em;
  background-color: #442e0a;
}
/* line 241, app/library/styles/less/common.less */
#verse-picker-container #aya-picker-container table {
  width: 100%;
  border-collapse: collapse;
}
/* line 244, app/library/styles/less/common.less */
#verse-picker-container #aya-picker-container table .aya-picker-number {
  text-align: center;
  text-decoration: none;
  color: white;
}
/* line 176, app/library/styles/less/common.less */
#verse-picker-container #aya-picker-container table .aya-picker-number:hover {
  color: #689862;
  cursor: hand;
}
/* line 250, app/library/styles/less/common.less */
#verse-picker-container #aya-picker-container table td {
  padding: 0.25em;
  border: 1px;
}
/* line 253, app/library/styles/less/common.less */
#verse-picker-container #aya-picker-container table td .tpd-title {
  color: #fafcfa;
  padding-top: 5px;
  padding-bottom: 5px;
}
/* line 265, app/library/styles/less/common.less */
.page-content {
  text-align: center;
  margin: auto;
}
/* line 269, app/library/styles/less/common.less */
#_404 .page-content {
  max-width: 600px;
}
/* line 273, app/library/styles/less/common.less */
#customer-statistics .page-content {
  max-width: 1100px;
}
/* line 277, app/library/styles/less/common.less */
.about .page-content {
  max-width: 1000px;
  line-height: 150%;
}
/* line 282, app/library/styles/less/common.less */
#about-history .page-content {
  text-align: left;
}
/* line 286, app/library/styles/less/common.less */
#cookie-policy .page-content,
#home .page-content {
  max-width: 900px;
}
/* line 295, app/library/styles/less/common.less */
#verses #verse-picker-container {
  max-width: 1000px;
  border: 1px #2e1f07 solid;
  padding: 0.5em;
}
/* line 299, app/library/styles/less/common.less */
#verses #verse-picker-container #aya-picker-container {
  margin-left: -0.5em;
  margin-right: -0.5em;
}
/* line 307, app/library/styles/less/common.less */
#scrollTopFloatingButton {
  /* Hidden by default */
  display: none;
  position: fixed;
  bottom: 47px;
  right: 12px;
  /* Make sure it does not overlap */
  z-index: 99;
  border: none;
  outline: none;
  background-color: #689862;
  color: #e6e1dc;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 10px;
  font-size: 15.4px;
  opacity: 0.95;
}
/* line 324, app/library/styles/less/common.less */
#scrollTopFloatingButton:hover {
  background-color: #4c604c;
}
/* line 330, app/library/styles/less/common.less */
#pageNavigatorFloatingButton {
  position: fixed;
  right: 15px;
  bottom: 10px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #689862;
  color: #e6e1dc;
  padding: 0.5em;
  border-radius: 10px;
  font-size: 15.4px;
  opacity: 0.95;
}
/* line 343, app/library/styles/less/common.less */
#pageNavigatorFloatingButton:hover {
  background-color: #4c604c;
}
/* line 348, app/library/styles/less/common.less */
#pageNavigatorFixed {
  text-align: center;
  font-size: 15.4px;
  color: #2e1f07;
}
/* line 352, app/library/styles/less/common.less */
#pageNavigatorFixed a:link {
  color: #689862;
}
/* line 355, app/library/styles/less/common.less */
#pageNavigatorFixed a:visited {
  color: #689862;
}
/* line 358, app/library/styles/less/common.less */
#pageNavigatorFixed a:hover {
  color: #4c604c;
}
/* line 364, app/library/styles/less/common.less */
.search-form {
  margin: auto;
  max-width: 900px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  padding: 1em;
}
/* line 374, app/library/styles/less/common.less */
#floating-message {
  display: none;
  position: fixed;
  left: 50%;
  width: 30%;
  margin-left: -15%;
  top: 50%;
  height: 20px;
  /*set to a negative number 1/2 of your height*/
  margin-top: -10px;
  /* Make sure it does not overlap */
  z-index: 99;
  border: none;
  outline: none;
  background-color: #689862;
  color: #e6e1dc;
  cursor: pointer;
  padding: 1em;
  border-radius: 10px;
  font-size: 15.4px;
  opacity: 0.95;
}
/* line 397, app/library/styles/less/common.less */
.floating-message-higher {
  margin-top: -50px !important;
}
/* line 401, app/library/styles/less/common.less */
.chapter-heading {
  font-weight: bold;
  font-size: larger;
}
/* line 406, app/library/styles/less/common.less */
.message {
  font-weight: bold;
  text-align: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
/* line 414, app/library/styles/less/common.less */
.message-warning {
  color: red;
}
/* line 418, app/library/styles/less/common.less */
.message-success {
  color: #689862;
}
/* line 422, app/library/styles/less/common.less */
.message-at-top-of-interest-page-after-action {
  margin-top: 1em;
  margin-bottom: 1em;
}
/* line 427, app/library/styles/less/common.less */
.message-at-top-of-interest-page-after-action-less-padding {
  margin-top: 0.25em;
  margin-bottom: 1em;
}
/* line 432, app/library/styles/less/common.less */
.message-at-top-of-search-browse-page-after-action {
  margin-top: 0.5em;
  margin-bottom: -0.75em;
}
/* line 437, app/library/styles/less/common.less */
.top-padding-only {
  margin-top: 0.5em;
}
/* line 441, app/library/styles/less/common.less */
.smaller_text_for_mini_dialogs {
  font-size: 10.5px;
}
/* line 445, app/library/styles/less/common.less */
.hidden {
  display: none;
}
/* line 449, app/library/styles/less/common.less */
.verseTools {
  display: none;
  white-space: nowrap;
}
/* line 454, app/library/styles/less/common.less */
.toggle-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
/* line 458, app/library/styles/less/common.less */
.toggle-button button {
  margin: 0px;
  border: 1px solid #689862;
  font-size: larger;
  padding: 1em;
}
/* line 464, app/library/styles/less/common.less */
.toggle-button button.not-selected,
.toggle-button button.not-selected:focus,
.toggle-button button.not-selected:active,
.toggle-button button.not-selected:hover {
  color: black;
  background-color: #e6e1dc;
}
/* line 472, app/library/styles/less/common.less */
.toggle-button button.selected,
.toggle-button button.selected:active,
.toggle-button button.selected:focus,
.toggle-button button.selected:hover {
  color: white;
  background-color: #689862;
  font-weight: bold;
}
/* line 480, app/library/styles/less/common.less */
.toggle-button button:nth-child(1) {
  border-top-left-radius: 5pt;
  border-bottom-left-radius: 5pt;
}
/* line 484, app/library/styles/less/common.less */
.toggle-button button:nth-last-child(1) {
  border-top-right-radius: 5pt;
  border-bottom-right-radius: 5pt;
}
/* line 494, app/library/styles/less/common.less */
.float-right {
  float: right;
}
/* line 498, app/library/styles/less/common.less */
.source-document-text {
  max-width: 1000px;
  text-align: justify;
  line-height: 200%;
}
/* line 506, app/library/styles/less/common.less */
.green-text {
  color: #689862;
}
/* line 510, app/library/styles/less/common.less */
.gray-text {
  color: gray;
}
/* footer and other non-menu settings (inc body tags and flex settings) */
/* line 516, app/library/styles/less/common.less */
.qt-site {
  display: flex;
  flex-direction: column;
  margin: 0px 20px 0px 20px;
  min-height: 100vh;
}
/* line 524, app/library/styles/less/common.less */
.qt-site-content,
.qt-site-footer {
  text-align: center;
  margin: auto;
}
/* line 530, app/library/styles/less/common.less */
.qt-site-content {
  flex: 1;
}
/* line 534, app/library/styles/less/common.less */
.qt-site-footer {
  width: 100%;
}
/* line 538, app/library/styles/less/common.less */
.footer-content-holder {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 50px;
  background: #2e1f07;
  color: #e0e0e0;
  text-align: center;
  font-size: 80%;
  font-family: Arial, Helvetica, sans-serif;
}
/* line 549, app/library/styles/less/common.less */
.footer-content-holder a {
  color: white;
}
/* line 554, app/library/styles/less/common.less */
.footer-icons {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.1em;
}
/* line 563, app/library/styles/less/common.less */
.print {
  display: none;
}
/* line 567, app/library/styles/less/common.less */
.loading {
  display: none;
  background: url('/images/loading_big.gif');
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -150px;
  margin-left: -150px;
  width: 300px;
  height: 300px;
  background-repeat: no-repeat;
}
/* line 580, app/library/styles/less/common.less */
.element-spinner {
  background: url(/images/loading_big.gif);
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
}
@media print {
  /* line 590, app/library/styles/less/common.less */
  .footer-icons .qt-mini-logo {
    display: none;
  }
  /* line 593, app/library/styles/less/common.less */
  .footer-icons .qt-mini-logo.print {
    display: inherit;
  }
  /* line 596, app/library/styles/less/common.less */
  .print-control {
    display: none;
  }
  /* line 600, app/library/styles/less/common.less */
  .footer-content-holder {
    background: none;
  }
  /* line 602, app/library/styles/less/common.less */
  .footer-content-holder a {
    color: #4c604c;
    font-size: large;
  }
}
/* line 609, app/library/styles/less/common.less */
#popup-verse-browser {
  overflow-y: scroll;
  width: 900px;
  max-height: 250px;
}
/* line 613, app/library/styles/less/common.less */
#popup-verse-browser > * {
  text-align: center;
}
