/* standard values used across application */
/* palette of colours from style guide */
/* heights of position:fixed elements that we need to add spacer divs for for desktop screen width */
/* heights of position:fixed elements that we need to add spacer divs for for iPhone 6s screen width in landscape mode */
/* Base Styles */
/* line 5, app/library/styles/less/qt_menu.less */
#qt-menu > ul,
#qt-menu > ul li,
#qt-menu > ul ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: Cabin, Arial, Helvetica, sans-serif;
}
/* line 14, app/library/styles/less/qt_menu.less */
#qt-menu > ul {
  position: relative;
  z-index: 10;
}
/* line 19, app/library/styles/less/qt_menu.less */
#qt-menu > ul li {
  float: left;
  min-height: 1px;
  line-height: 1.3em;
  vertical-align: middle;
}
/* line 26, app/library/styles/less/qt_menu.less */
#qt-menu > ul li.hover,
#qt-menu > ul li:hover {
  position: relative;
  z-index: 10;
  cursor: default;
}
/* line 33, app/library/styles/less/qt_menu.less */
#qt-menu > ul ul {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 598;
  width: auto;
  white-space: nowrap;
  border: 1px solid #ccc \9;
  -webkit-box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.15);
}
/* line 47, app/library/styles/less/qt_menu.less */
#qt-menu > ul ul li {
  float: none;
}
/* line 51, app/library/styles/less/qt_menu.less */
#qt-menu > ul ul ul {
  top: 1px;
  left: 99%;
}
/* line 56, app/library/styles/less/qt_menu.less */
#qt-menu > ul li:hover > ul {
  visibility: visible;
}
/* Align last drop down RTL */
/* line 62, app/library/styles/less/qt_menu.less */
#qt-menu > ul > li.last ul ul {
  left: auto !important;
  right: 99%;
}
/* line 67, app/library/styles/less/qt_menu.less */
#qt-menu > ul > li.last ul {
  left: auto;
  right: 0;
}
/* line 72, app/library/styles/less/qt_menu.less */
#qt-menu > ul > li.last {
  text-align: right;
}
/* line 76, app/library/styles/less/qt_menu.less */
#qt-menu.align-center > ul > li {
  float: none;
  display: inline-block;
}
/* line 81, app/library/styles/less/qt_menu.less */
#qt-menu.align-center > ul {
  text-align: center;
  font-size: 0;
}
/* line 86, app/library/styles/less/qt_menu.less */
#qt-menu > ul > li {
  font-size: 14px;
  display: block;
}
/* line 91, app/library/styles/less/qt_menu.less */
#qt-menu ul ul {
  text-align: left;
}
/* line 95, app/library/styles/less/qt_menu.less */
#qt-menu.align-right > ul > li {
  float: right;
}
/* line 99, app/library/styles/less/qt_menu.less */
#qt-menu.align-right > ul ul ul {
  top: 1px;
  left: auto;
  right: 99%;
}
/* Theme Styles */
/* line 107, app/library/styles/less/qt_menu.less */
#qt-menu > ul {
  font-family: sans-serif;
  font-size: 14;
  width: auto;
  zoom: 1;
}
/* line 114, app/library/styles/less/qt_menu.less */
#qt-menu > ul:before {
  content: '';
  display: block;
}
/* line 119, app/library/styles/less/qt_menu.less */
#qt-menu > ul:after {
  content: '';
  display: table;
  clear: both;
}
/* line 125, app/library/styles/less/qt_menu.less */
#qt-menu > ul li a {
  display: inline-block;
  padding: 10px 18px;
}
/* line 130, app/library/styles/less/qt_menu.less */
#qt-menu > ul > li.active,
#qt-menu > ul > li.active:hover {
  background-color: #3fa338;
}
/* line 135, app/library/styles/less/qt_menu.less */
#qt-menu > ul > li > a:link,
#qt-menu > ul > li > a:active,
#qt-menu > ul > li > a:visited {
  color: white;
}
/* line 141, app/library/styles/less/qt_menu.less */
#qt-menu > ul > li > a:hover {
  color: #e6e1dc;
}
/* line 145, app/library/styles/less/qt_menu.less */
#qt-menu > ul ul ul {
  top: 0;
}
/* line 149, app/library/styles/less/qt_menu.less */
#qt-menu > ul li li {
  /* this is the background colour of the sub menus */
  border-bottom: 1px solid #fafcfa;
  background-color: #2e1f07;
  font-size: 14.7px;
  display: table;
  width: 100%;
}
/* line 158, app/library/styles/less/qt_menu.less */
#qt-menu > ul li.hover,
#qt-menu > ul li:hover {
  background-color: #689862;
  /* this is the colour of the highlight box on the sub menus */
}
/* line 164, app/library/styles/less/qt_menu.less */
#qt-menu > ul > li.hover,
#qt-menu > ul > li:hover {
  background-color: #303030;
  /* this is the colour of the highlight box on the main menu bar */
  -webkit-box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
}
/* line 173, app/library/styles/less/qt_menu.less */
#qt-menu > ul a:link,
#qt-menu > ul a:visited {
  color: #d0d0d0;
  text-decoration: none;
}
/* line 179, app/library/styles/less/qt_menu.less */
.MenuLink {
  display: block !important;
  /* Means links on the menus work if you click anywhere on their bar, not just their text */
}
/* line 184, app/library/styles/less/qt_menu.less */
#qt-menu > ul a:hover {
  color: #d0d0d0;
}
/* line 188, app/library/styles/less/qt_menu.less */
#qt-menu > ul a:active {
  color: #d0d0d0;
}
/* line 192, app/library/styles/less/qt_menu.less */
#qt-menu > ul > li > a {
  font-size: 14.7px;
}
/* Make this menu into a fixed/static navbar that stays put despite scrolling */
/* line 198, app/library/styles/less/qt_menu.less */
#qt-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #4c604c;
  z-index: 10;
}
/* line 205, app/library/styles/less/qt_menu.less */
#qt-menu .menu-icon {
  vertical-align: middle;
  padding-left: 0.5em;
  height: 18px;
  width: 18px;
}
/* line 211, app/library/styles/less/qt_menu.less */
#qt-menu #my-profile-menu ul {
  min-width: 290px;
}
/* line 213, app/library/styles/less/qt_menu.less */
#qt-menu #my-profile-menu ul li {
  white-space: normal;
}
@media print {
  /* line 220, app/library/styles/less/qt_menu.less */
  #qt-menu {
    display: none;
  }
}
/* line 225, app/library/styles/less/qt_menu.less */
#floatarrow {
  color: #d0d0d0;
  display: table-cell;
  text-align: right;
  vertical-align: middle;
  padding-right: 6px;
}
/* The persistent div used, below the menu bar, on verse_browser.php & verse_browser.php for status info */
/* line 238, app/library/styles/less/qt_menu.less */
#lower-status-bar {
  position: fixed;
  top: 102px;
  left: 20;
  width: 100%;
  margin-left: -20px;
  margin-right: -20px;
}
/* line 246, app/library/styles/less/qt_menu.less */
#lower-status-bar td {
  text-align: center;
}
/* line 249, app/library/styles/less/qt_menu.less */
#lower-status-bar tr.show-search-params-and-hits {
  background-color: #909090;
}
/* line 251, app/library/styles/less/qt_menu.less */
#lower-status-bar tr.show-search-params-and-hits td {
  padding: 8px;
}
/* line 254, app/library/styles/less/qt_menu.less */
#lower-status-bar tr.show-search-params-and-hits #hit_counter_wrapper {
  margin-top: 5px;
  font-size: smaller;
}
/* line 259, app/library/styles/less/qt_menu.less */
#lower-status-bar tr.extra-controls {
  background-color: #c0c0c0;
}
/* line 261, app/library/styles/less/qt_menu.less */
#lower-status-bar tr.extra-controls .extra-controls-container {
  display: flex;
  /* ensures the three button-group items flow horizonally as flex items */
  padding: 4px 2px;
}
/* line 265, app/library/styles/less/qt_menu.less */
#lower-status-bar tr.extra-controls .extra-controls-container .button-group {
  /* ensures the individual buttons flow horizonatally as flex items */
  display: flex;
  flex: 1 1 33%;
  justify-content: center;
  white-space: nowrap;
}
/* line 271, app/library/styles/less/qt_menu.less */
#lower-status-bar tr.extra-controls .extra-controls-container .button-group a {
  /* hides extra link underlines after the button due */
  text-decoration: none;
}
/* line 276, app/library/styles/less/qt_menu.less */
#lower-status-bar tr.extra-controls .extra-controls-container .button-group:first-child {
  justify-content: flex-start;
}
/* line 279, app/library/styles/less/qt_menu.less */
#lower-status-bar tr.extra-controls .extra-controls-container .button-group:last-child {
  justify-content: flex-end;
}
/* construct the search bar */
/* line 288, app/library/styles/less/qt_menu.less */
#search-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 43px;
  background-color: #4c604c;
  display: none;
  text-align: initial;
}
/* line 299, app/library/styles/less/qt_menu.less */
#pickverse {
  vertical-align: middle;
}
/* line 303, app/library/styles/less/qt_menu.less */
.search-bar-span {
  height: 24px;
  width: 24px;
  display: inline-block;
  vertical-align: middle;
}
/* line 310, app/library/styles/less/qt_menu.less */
.search-icon {
  margin-right: 1.1em;
}
/* line 314, app/library/styles/less/qt_menu.less */
.close-search-icon {
  margin-left: 1.1em;
  height: 15px;
  width: 15px;
}
/* line 320, app/library/styles/less/qt_menu.less */
#search-tool {
  width: 100%;
  align-self: center;
  background: #4c604c;
  padding: 8px;
  color: white;
}
/* line 328, app/library/styles/less/qt_menu.less */
#SEARCH_CLOSE_ICON {
  height: 15px;
  width: 15px;
}
/* line 333, app/library/styles/less/qt_menu.less */
#SEARCH_CLOSE_ICON:hover {
  opacity: 0.5;
}
/* line 337, app/library/styles/less/qt_menu.less */
#miniSearchBox {
  font-size: 14px;
  background-color: #4c604c;
  border: 0px;
  color: white;
  outline: none;
  width: 75%;
}
/* line 346, app/library/styles/less/qt_menu.less */
#search-suggestions {
  position: fixed;
  padding: 8px;
  top: 43px;
  left: 0px;
  margin-left: 0.5em;
  width: 80%;
  background-color: #f0f0f0;
  z-index: 10;
  display: none;
  text-align: initial;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
/* line 358, app/library/styles/less/qt_menu.less */
#search-suggestions table {
  border-collapse: collapse;
  margin-bottom: 4px;
  width: 100%;
  color: black;
}
/* line 364, app/library/styles/less/qt_menu.less */
#search-suggestions th {
  font-weight: bold;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 90%;
}
/* line 370, app/library/styles/less/qt_menu.less */
#search-suggestions td {
  padding: 4px;
  color: white;
}
/* line 376, app/library/styles/less/qt_menu.less */
.suggested-row a {
  color: black;
  text-decoration: none;
  font-size: 100%;
}
/* line 382, app/library/styles/less/qt_menu.less */
.suggested-row a:hover {
  color: blue;
}
/* line 386, app/library/styles/less/qt_menu.less */
.suggested-row:focus {
  background-color: yellow;
}
/* line 390, app/library/styles/less/qt_menu.less */
#PressEnter {
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
}
/* line 396, app/library/styles/less/qt_menu.less */
.SearchSuggestionsStandard {
  color: #585858;
}
/* line 400, app/library/styles/less/qt_menu.less */
.SearchSuggestionsEmphasised {
  color: black;
}
/* line 404, app/library/styles/less/qt_menu.less */
#qt-menu .mobile-warning {
  color: orange;
  padding: 0.5em;
  padding-top: 0px;
  text-align: center;
  font-size: smaller;
}
/* Used in verse_browser.php : start */
/* line 414, app/library/styles/less/qt_menu.less */
br {
  line-height: 150%;
}
/* line 418, app/library/styles/less/qt_menu.less */
#search-results {
  margin-top: -2px;
}
/* line 420, app/library/styles/less/qt_menu.less */
#search-results .text-reference {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
/* line 427, app/library/styles/less/qt_menu.less */
#verse-results .text-reference {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
/* Used in verse_browser.php : end */
/* line 435, app/library/styles/less/qt_menu.less */
.main-menu-spacer {
  height: 102px;
}
/* line 439, app/library/styles/less/qt_menu.less */
.verses-and-search-buttons-verses-spacer.formula-summary-spacer {
  height: 144px;
}
/* line 444, app/library/styles/less/qt_menu.less */
.verses-and-search-buttons-verses-spacer {
  height: 119px;
}
/* line 448, app/library/styles/less/qt_menu.less */
.verses-and-search-buttons-search-spacer.formula-summary-spacer {
  height: 142px;
}
/* line 453, app/library/styles/less/qt_menu.less */
.verses-and-search-buttons-search-spacer {
  height: 117px;
}
/* line 457, app/library/styles/less/qt_menu.less */
.extra-controls-container button {
  height: 100%;
}
/*************************************
 * MEDIA QUERIES NEED TO BE AT THE END
 * OF THIS FILE OTHERWISE THEY WILL BE
 * OVERRIDDEN BY WHAT COMES AFTER THEM
 *************************************/
/* media query sets the cut off as a Moto G4  */
@media screen and (min-width: 731px) {
  /* line 469, app/library/styles/less/qt_menu.less */
  .mobile-warning {
    display: none;
  }
  /* line 472, app/library/styles/less/qt_menu.less */
  #lower-status-bar {
    top: 43px;
  }
  /* line 475, app/library/styles/less/qt_menu.less */
  .main-menu-spacer {
    height: 43px;
  }
  /* line 478, app/library/styles/less/qt_menu.less */
  .verses-and-search-buttons-verses-spacer.formula-summary-spacer {
    height: 108px;
  }
  /* line 481, app/library/styles/less/qt_menu.less */
  .verses-and-search-buttons-verses-spacer {
    height: 83px;
  }
  /* line 484, app/library/styles/less/qt_menu.less */
  .verses-and-search-buttons-search-spacer.formula-summary-spacer {
    height: 124px;
  }
  /* line 487, app/library/styles/less/qt_menu.less */
  .verses-and-search-buttons-search-spacer {
    height: 99px;
  }
}
@media print {
  /* line 494, app/library/styles/less/qt_menu.less */
  #qt-menu {
    display: none !important;
  }
  /* line 497, app/library/styles/less/qt_menu.less */
  #ChangesFoundAndChangesNavTools {
    display: none !important;
  }
  /* line 500, app/library/styles/less/qt_menu.less */
  .button-block-with-spacing {
    display: none !important;
  }
  /* line 503, app/library/styles/less/qt_menu.less */
  .main-menu-spacer {
    height: unset;
  }
}
/*************************************
 * MEDIA QUERIES NEED TO BE AT THE END
 * OF THIS FILE OTHERWISE THEY WILL BE
 * OVERRIDDEN BY WHAT COMES AFTER THEM
 *************************************/
