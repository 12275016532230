/* standard values used across application */
/* palette of colours from style guide */
/* heights of position:fixed elements that we need to add spacer divs for for desktop screen width */
/* heights of position:fixed elements that we need to add spacer divs for for iPhone 6s screen width in landscape mode */
/* Style the tab */
/* line 4, app/library/styles/less/tabs.less */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}
/* Style the buttons that are used to open the tab content */
/* line 11, app/library/styles/less/tabs.less */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}
/* Change background color of buttons on hover */
/* line 22, app/library/styles/less/tabs.less */
.tab button:hover {
  background-color: #ddd;
}
/* Create an active/current tablink class */
/* line 27, app/library/styles/less/tabs.less */
.tab button.active {
  background-color: #689862;
  color: white;
}
/* Style the tab content */
/* line 33, app/library/styles/less/tabs.less */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
/* line 40, app/library/styles/less/tabs.less */
.tabWrapper {
  width: 950px;
  margin: 0 auto;
}
