/* standard values used across application */
/* palette of colours from style guide */
/* heights of position:fixed elements that we need to add spacer divs for for desktop screen width */
/* heights of position:fixed elements that we need to add spacer divs for for iPhone 6s screen width in landscape mode */
/* line 3, app/library/styles/less/home.less */
#home-content {
  width: 640px;
  border: 0px solid #000000;
  line-height: 120%;
}
/* line 7, app/library/styles/less/home.less */
#home-content .mainLogo {
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  text-align: center;
}
/* line 13, app/library/styles/less/home.less */
#home-content #search-controls {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
}
/* line 17, app/library/styles/less/home.less */
#home-content #search-controls #left-side-controls,
#home-content #search-controls #right-side-controls {
  display: flex;
  flex-direction: column;
}
/* line 22, app/library/styles/less/home.less */
#home-content #search-controls #left-side-controls {
  margin-right: 0.5em;
  flex-grow: 1;
}
/* line 28, app/library/styles/less/home.less */
#home-content form button,
#home-content form input {
  font-size: 17.4px;
  padding: 0.5em;
  margin-bottom: 0.5em;
}
/* line 34, app/library/styles/less/home.less */
#home-content form a#ok-button {
  color: black;
  text-decoration: none;
  font-size: 17.4px;
  background-image: url("/images/qt-mini-logo.png");
  background-size: 1.5em;
  background-position: 0.5em;
  background-repeat: no-repeat;
  padding: 0.5em;
  line-height: 1.5em;
  color: white;
  padding-left: 2.5em;
  background-color: #4c604c;
  margin-bottom: 0.5em;
}
/* line 176, app/library/styles/less/common.less */
#home-content form a#ok-button:hover {
  color: #689862;
  cursor: hand;
}
/* line 49, app/library/styles/less/home.less */
#home-content #drop-down-controls,
#home-content #easy-search {
  display: inline;
  font-size: smaller;
  text-align: left;
}
/* line 54, app/library/styles/less/home.less */
#home-content #drop-down-controls a,
#home-content #easy-search a {
  margin-right: 1em;
}
/* line 58, app/library/styles/less/home.less */
#home-content #info-area {
  display: flex;
  flex-direction: row;
}
/* line 61, app/library/styles/less/home.less */
#home-content #info-area #tipped-expander {
  width: 0px;
}
/* line 64, app/library/styles/less/home.less */
#home-content #info-area #quick-tips {
  border: 1px solid #2e1f07;
  display: none;
  background-color: #fafcfa;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  margin: 40px;
  margin-top: 80px;
}
/* line 71, app/library/styles/less/home.less */
#home-content #info-area #quick-tips div {
  padding: 0.5em;
}
/* line 74, app/library/styles/less/home.less */
#home-content #info-area #quick-tips .header {
  text-align: center;
  font-weight: bold;
  font-size: larger;
  background-color: #e6e1dc;
  margin-bottom: 0px;
}
/* line 80, app/library/styles/less/home.less */
#home-content #info-area #quick-tips .header .hide-quick-tips {
  float: right;
  color: black;
}
/* line 85, app/library/styles/less/home.less */
#home-content #info-area #quick-tips .content {
  display: flex;
  flex-direction: column;
  padding: 0px;
  text-align: left;
  min-height: 100px;
  min-width: 560px;
}
/* line 92, app/library/styles/less/home.less */
#home-content #info-area #quick-tips .content .example {
  margin-left: 20px;
  font-weight: bold;
}
/* line 95, app/library/styles/less/home.less */
#home-content #info-area #quick-tips .content .example a {
  color: black;
  text-decoration: none;
}
/* line 176, app/library/styles/less/common.less */
#home-content #info-area #quick-tips .content .example a:hover {
  color: #689862;
  cursor: hand;
}
/* line 100, app/library/styles/less/home.less */
#home-content #info-area #quick-tips .footer {
  border: 0px;
  background-color: transparent;
  font-size: 15.4px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0.5em;
  position: relative;
  text-align: left;
}
/* line 108, app/library/styles/less/home.less */
#home-content #info-area #quick-tips .footer .quick-tip-navigation {
  position: absolute;
  right: 0px;
  top: -4px;
}
/* line 112, app/library/styles/less/home.less */
#home-content #info-area #quick-tips .footer .quick-tip-navigation a.button {
  padding: 0.5em;
  margin: 2px;
  background-color: #e6e1dc;
  border-radius: 5px;
  color: black;
}
/* line 125, app/library/styles/less/home.less */
.tipped-container .tipped-scroller {
  border: 1px solid #2e1f07;
  overflow-y: scroll;
}
/* line 128, app/library/styles/less/home.less */
.tipped-container .tipped-scroller table {
  width: 100%;
  border: 0px;
  margin-bottom: 0.5em;
}
/* line 132, app/library/styles/less/home.less */
.tipped-container .tipped-scroller table td a.linky {
  font-size: small;
}
/* line 136, app/library/styles/less/home.less */
.tipped-container .tipped-scroller .delete {
  margin: 0.5em;
  font-weight: bold;
}
/* line 143, app/library/styles/less/home.less */
#bookmarks-container #bookmarks-scroller {
  min-width: 400px;
  height: 250px;
}
/* line 150, app/library/styles/less/home.less */
#history-container #history-scroller {
  min-width: 300px;
  height: 250px;
}
/* line 156, app/library/styles/less/home.less */
#search-commands-container {
  display: none;
  z-index: 100;
}
/* line 159, app/library/styles/less/home.less */
#search-commands-container #search-commands {
  display: flex;
  flex-direction: row;
}
/* line 162, app/library/styles/less/home.less */
#search-commands-container #search-commands .left {
  margin-right: 20px;
}
/* line 168, app/library/styles/less/home.less */
#tags-container {
  display: none;
  z-index: 100;
}
/* line 171, app/library/styles/less/home.less */
#tags-container #tags-scroller {
  min-width: 300px;
  height: 250px;
}
/* line 177, app/library/styles/less/home.less */
#roots-container {
  display: none;
}
/* line 179, app/library/styles/less/home.less */
#roots-container #roots-scroller {
  height: 300px;
}
/* line 181, app/library/styles/less/home.less */
#roots-container #roots-scroller .header {
  background-color: #e6e1dc;
  text-align: center;
}
/* line 184, app/library/styles/less/home.less */
#roots-container #roots-scroller .header td {
  padding: 0px;
}
/* line 186, app/library/styles/less/home.less */
#roots-container #roots-scroller .header td .title {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  font-size: larger;
  font-weight: bold;
}
/* line 194, app/library/styles/less/home.less */
#roots-container #roots-scroller td {
  padding: 0.25em;
}
/* line 196, app/library/styles/less/home.less */
#roots-container #roots-scroller td a.linky {
  font-size: small;
}
/* line 203, app/library/styles/less/home.less */
#keyboard-container {
  display: none;
}
/* line 205, app/library/styles/less/home.less */
#keyboard-container #flipper {
  text-align: right;
}
/* line 208, app/library/styles/less/home.less */
#keyboard-container #keyboard {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: larger;
}
/* line 217, app/library/styles/less/home.less */
#keyboard-container #keyboard .column {
  display: flex;
  flex-direction: column;
  border: solid #2e1f07;
  border-width: 1px 0px 1px 1px;
}
/* line 222, app/library/styles/less/home.less */
#keyboard-container #keyboard .column:last-child {
  border-right-width: 1px;
}
/* line 226, app/library/styles/less/home.less */
#keyboard-container #keyboard .column > * {
  border: solid #2e1f07;
  padding: 0.25em;
  border-width: 0px 0px 1px 0px;
  cursor: pointer;
  text-align: center;
}
/* line 233, app/library/styles/less/home.less */
#keyboard-container #keyboard .column > div:hover {
  color: #689862;
}
/* line 236, app/library/styles/less/home.less */
#keyboard-container #keyboard .column .arabic-letter {
  background-color: #e6e1dc;
}
/* line 239, app/library/styles/less/home.less */
#keyboard-container #keyboard .column .substitute-translit {
  background-color: #d1c7be;
}
/* line 243, app/library/styles/less/home.less */
#keyboard-container #keyboard .column .buckwalter-letter {
  border-width: 0px;
  background-color: #d1c7be;
}
/* line 252, app/library/styles/less/home.less */
#extra-characters {
  overflow-y: scroll;
  height: 250px;
}
/* line 255, app/library/styles/less/home.less */
#extra-characters > * {
  text-align: center;
}
/* line 258, app/library/styles/less/home.less */
#extra-characters .arabic-glyph {
  font-size: x-large;
}
/* line 263, app/library/styles/less/home.less */
.commandHelpTextHomePage {
  font-size: 15.4px;
  width: 600px;
}
/* line 268, app/library/styles/less/home.less */
#transcription-action-needed-dan {
  padding: 0.6em;
  text-align: center;
  font-weight: bold;
}
/* line 274, app/library/styles/less/home.less */
.message_block_home_page {
  margin: 0 auto;
  padding: 6px 9px;
  border: 1px solid #689862;
  background-color: #e6e1dc;
  width: 600px;
  line-height: 150%;
}
