/* standard values used across application */
/* palette of colours from style guide */
/* heights of position:fixed elements that we need to add spacer divs for for desktop screen width */
/* heights of position:fixed elements that we need to add spacer divs for for iPhone 6s screen width in landscape mode */
/* line 3, app/library/styles/less/tables.less */
#manage-users_wrapper {
  /*
   Since we are using DataTables, we need to set the width of the DIV containing
   the TABLE as well as all the other stuff that DataTables adds.
   */
  width: 1100px;
}
/* line 11, app/library/styles/less/tables.less */
.qt-table {
  padding-top: 0.5em;
}
/* line 14, app/library/styles/less/tables.less */
.qt-table th,
.qt-table td {
  text-align: center;
}
/* line 17, app/library/styles/less/tables.less */
.qt-table th *,
.qt-table td * {
  vertical-align: middle;
}
/* line 21, app/library/styles/less/tables.less */
.qt-table th .pull-right,
.qt-table td .pull-right {
  float: right;
}
/* line 25, app/library/styles/less/tables.less */
.qt-table th.left-align,
.qt-table td.left-align {
  text-align: left;
}
/* line 29, app/library/styles/less/tables.less */
.qt-table th.right-align,
.qt-table td.right-align {
  text-align: right;
}
/* line 40, app/library/styles/less/tables.less */
.qt-table tbody .row-number,
.qt-table tbody .authentication-type,
.qt-table tbody .ip-ranges {
  line-height: 1.5;
  text-align: center;
}
/* line 47, app/library/styles/less/tables.less */
.qt-table tbody .user-count,
.qt-table tbody .login-count,
.qt-table tbody .last-login,
.qt-table tbody .activity-count,
.qt-table tbody .administrator,
.qt-table tbody .is-active {
  text-align: center;
}
/* line 56, app/library/styles/less/tables.less */
.qt-table tbody td {
  font-size: 14px;
}
/* line 61, app/library/styles/less/tables.less */
.qt-table tfoot {
  font-weight: bold;
}
/*
 * Extra styling for DataTables to override their defaults
 */
/* line 70, app/library/styles/less/tables.less */
table.dataTable thead .sorting {
  background-image: url('../images/sort_both.png');
}
/* line 75, app/library/styles/less/tables.less */
.dataTables_wrapper .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* line 80, app/library/styles/less/tables.less */
.dataTables_wrapper .top button#all-users {
  font-weight: bold;
}
/**
 * These styles are intended to mimic #pageNavigatorFloatingButton
*/
/* line 89, app/library/styles/less/tables.less */
.dataTables_wrapper .dataTables_paginate {
  position: fixed;
  right: 15px;
  bottom: 10px;
  z-index: 99;
  background-color: #689862;
  color: #e6e1dc;
  padding: 0.5em;
  border-radius: 10px;
  font-size: 15.4px;
  opacity: 0.95;
}
/* line 101, app/library/styles/less/tables.less */
.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0px;
}
/* line 104, app/library/styles/less/tables.less */
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #689862 !important;
  background: none;
  border: none;
}
/* line 111, app/library/styles/less/tables.less */
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background: none;
  border: none;
  font-weight: bold;
  color: #e6e1dc !important;
}
/* line 117, app/library/styles/less/tables.less */
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: none;
  border: none;
}
/* line 123, app/library/styles/less/tables.less */
.dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover {
  background: none;
  border: none;
  color: #e6e1dc !important;
}
/* line 130, app/library/styles/less/tables.less */
.dataTables_wrapper .dataTables_paginate:hover {
  background-color: #4c604c;
}
/* 	TABLE CSS DEFINITION */
/* line 137, app/library/styles/less/tables.less */
.floatingTableHeader {
  position: fixed;
  top: 40px;
  visibility: hidden;
}
/* creates a small margin between the floating table header row and the website app header */
/* line 145, app/library/styles/less/tables.less */
.table-header-row {
  border-top: 1px solid gray;
}
/* line 149, app/library/styles/less/tables.less */
.hoverTable {
  border-collapse: collapse;
  max-width: 1300px;
}
/* line 153, app/library/styles/less/tables.less */
.hoverTable th {
  background: #e6e1dc;
  border: 1px solid gray;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  padding: 8px;
}
/* line 161, app/library/styles/less/tables.less */
.hoverTable td {
  border: 1px solid #b0b0b0;
  padding: 6px;
}
/* line 167, app/library/styles/less/tables.less */
.tightTable td {
  padding: 3px !important;
}
/* line 171, app/library/styles/less/tables.less */
.fixedTable {
  table-layout: fixed !important;
  word-wrap: break-word;
}
/* table container div and fixed cols solves wide table persistent header issues */
/* line 178, app/library/styles/less/tables.less */
.tableContainer {
  width: 1300px;
}
/* Define the default colours for all the table rows */
/* line 184, app/library/styles/less/tables.less */
.hoverTable tr:nth-child(even) {
  background: #ffffff;
}
/* line 188, app/library/styles/less/tables.less */
.hoverTable tr:nth-child(odd) {
  background: #f8f8f8;
}
/* line 195, app/library/styles/less/tables.less */
.complexIntertextTable_Light {
  background: white;
}
/* line 199, app/library/styles/less/tables.less */
.complexInterTextTable_Dark {
  background-color: #f8f8f8;
}
/* line 203, app/library/styles/less/tables.less */
.complexIntertextTable tr:hover td {
  background-color: #f5f5dd;
}
/* line 207, app/library/styles/less/tables.less */
.thickBorder {
  border-bottom: 2px solid #606060 !important;
}
/* Define the hover highlight color for the table row */
/* line 213, app/library/styles/less/tables.less */
.hoverTable tr:hover {
  background-color: #f5f5dd;
}
/* 		TABLE CSS DEFINITION */
/* 		A MUCH MORE MINIMALISTIC TABLE USED BY THE VERSE BROWSER */
/* line 221, app/library/styles/less/tables.less */
.verseBrowserTable {
  border-collapse: collapse;
}
/* line 225, app/library/styles/less/tables.less */
.verseBrowserTable td {
  border-collapse: collapse;
  padding: 6px;
}
/* line 230, app/library/styles/less/tables.less */
.verseBrowserTable:not(.nohover) tr:hover {
  background-color: #f5f5dd;
}
/* line 234, app/library/styles/less/tables.less */
.verseBrowserMinWidth {
  min-width: 97.5vw;
}
/* 		TABLE USED BY THE PREFERENCES PAGE */
/* line 240, app/library/styles/less/tables.less */
table#preferences {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1em;
}
/* line 245, app/library/styles/less/tables.less */
table#preferences td {
  padding: 6px;
  border-top: 1px solid #b0b0b0;
  border-bottom: 1px solid #b0b0b0;
}
/* line 250, app/library/styles/less/tables.less */
table#preferences td .preferences-button {
  width: 150px;
  float: right;
  padding: 3px 6px;
  font-size: 14px;
}
/* line 256, app/library/styles/less/tables.less */
table#preferences td button.preferences-button {
  margin-right: 0px;
}
/* line 261, app/library/styles/less/tables.less */
table#preferences td:first-child {
  border: 1px solid #b0b0b0;
}
/* line 265, app/library/styles/less/tables.less */
table#preferences td:last-child {
  border-right: 1px solid #b0b0b0;
}
/* line 269, app/library/styles/less/tables.less */
table#preferences th {
  border: 1px solid gray;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  padding: 8px;
  background: #c0c0c0;
}
/* line 278, app/library/styles/less/tables.less */
.formula-popup-list-table {
  border-collapse: collapse;
}
/* line 281, app/library/styles/less/tables.less */
.formula-popup-list-table td {
  color: white;
  border: 1px solid #202020;
  padding: 4px;
  font-size: 10.5px;
}
/* line 289, app/library/styles/less/tables.less */
.wordCorrectionTableMedium {
  font-size: 15pt;
  vertical-align: top;
}
/* line 294, app/library/styles/less/tables.less */
.wordCorrectionTableLarge {
  font-size: 20pt;
}
/* line 299, app/library/styles/less/tables.less */
#intertext-container #intertext-scroller {
  min-width: 680px;
  min-height: 200px;
}
/* line 306, app/library/styles/less/tables.less */
#intertext-table tbody {
  font-size: small;
}
/* 		USED BY login_logs.php */
/* line 314, app/library/styles/less/tables.less */
#login-logs-page #search-term {
  width: 220px;
}
/* line 319, app/library/styles/less/tables.less */
#login-logs-page table#login-logs th a.table-sorter img {
  margin-left: 3px;
}
/* line 322, app/library/styles/less/tables.less */
#login-logs-page table#login-logs td {
  text-align: center;
}
/* Used by word_correction_logs.php */
/* line 329, app/library/styles/less/tables.less */
.qt-table td button {
  padding: 2px;
  margin: 2px;
  font-size: smaller;
}
