/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 21, 2018 */
@font-face {
  font-family: 'cabin';
  src: url('/assets/fonts/cabin-bold-webfont.woff2') format('woff2'), url('/assets/fonts/cabin-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'cabin';
  src: url('/assets/fonts/cabin-regular-webfont.woff2') format('woff2'), url('/assets/fonts/cabin-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'cabin';
  src: url('/assets/fonts/cabin-italic-webfont.woff2') format('woff2'), url('/assets/fonts/cabin-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
