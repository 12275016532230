/* standard values used across application */
/* palette of colours from style guide */
/* heights of position:fixed elements that we need to add spacer divs for for desktop screen width */
/* heights of position:fixed elements that we need to add spacer divs for for iPhone 6s screen width in landscape mode */
/* line 5, app/library/styles/less/login_box.less */
.form button.cancel-button,
#cancelButton,
.cancel-button {
  background: #bf3131;
}
/* line 12, app/library/styles/less/login_box.less */
.form button.cancel-button:hover,
#cancelButton:hover,
.cancel-button:hover {
  background: #6f3737;
}
/* line 18, app/library/styles/less/login_box.less */
#login-forms {
  width: 410px;
}
/* line 21, app/library/styles/less/login_box.less */
#login-forms .heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: initial;
  margin-bottom: 1em;
}
/* line 29, app/library/styles/less/login_box.less */
#login-forms .heading img {
  margin-left: 0.5em;
}
/* line 42, app/library/styles/less/login_box.less */
.form,
.login-type {
  background: #ffffff;
  max-width: 360px;
  margin: 0 auto;
  padding: 1em 2em;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  font-family: Arial, Helvetica, sans-serif;
}
/* line 52, app/library/styles/less/login_box.less */
.form p,
.login-type p {
  line-height: 130%;
}
/* line 56, app/library/styles/less/login_box.less */
.form h3,
.login-type h3 {
  margin-top: 0px;
}
/* line 60, app/library/styles/less/login_box.less */
.form input.error-message,
.login-type input.error-message,
.form textarea.error-message,
.login-type textarea.error-message,
.form select.error-message,
.login-type select.error-message {
  margin-bottom: 3px;
}
/* line 66, app/library/styles/less/login_box.less */
.form label.error-message,
.login-type label.error-message {
  display: block;
  margin-bottom: 12px;
}
/* line 71, app/library/styles/less/login_box.less */
.form textarea,
.login-type textarea,
.form select,
.login-type select,
.form .checkbox-group,
.login-type .checkbox-group,
.form input,
.login-type input {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 0.5em;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  text-align: start;
}
/* line 87, app/library/styles/less/login_box.less */
.form input[type='checkbox'],
.login-type input[type='checkbox'] {
  vertical-align: middle;
  position: relative;
  width: 15%;
  bottom: 1px;
  margin-bottom: 0px;
  font-size: 10.5pt;
}
/* line 96, app/library/styles/less/login_box.less */
.form input[type='submit'],
.login-type input[type='submit'] {
  background: #689862;
  text-align: center;
}
/* line 101, app/library/styles/less/login_box.less */
.form input[type='date'],
.login-type input[type='date'] {
  height: 46px;
}
/* line 105, app/library/styles/less/login_box.less */
.form input[type='submit']:hover,
.login-type input[type='submit']:hover {
  background: #4c604c;
}
/* line 109, app/library/styles/less/login_box.less */
.form input[type='text']:read-only,
.login-type input[type='text']:read-only {
  color: dimgrey;
}
/* line 113, app/library/styles/less/login_box.less */
.form button,
.login-type button {
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #689862;
  margin: 0.25em 0px;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
/* line 129, app/library/styles/less/login_box.less */
.form button:hover,
.login-type button:hover,
.form button:active,
.login-type button:active,
.form button:focus,
.login-type button:focus,
.form #idpSelect input[type='submit']:hover,
.login-type #idpSelect input[type='submit']:hover {
  background: #4c604c;
}
/* line 136, app/library/styles/less/login_box.less */
.form .message,
.login-type .message {
  margin: 0.5em 0 0;
  color: #6a7087;
  font-size: 12px;
  font-weight: normal;
}
/* line 142, app/library/styles/less/login_box.less */
.form .message a,
.login-type .message a {
  color: rgba(0, 0, 255, 0.62);
  text-decoration: none;
}
/* line 148, app/library/styles/less/login_box.less */
.form .message.in-between,
.login-type .message.in-between {
  margin: 0.5em;
  margin-top: 0em;
}
/* line 154, app/library/styles/less/login_box.less */
.form .message.callout,
.login-type .message.callout {
  font-size: 10.5pt;
  margin-bottom: 0.5em;
}
/* line 159, app/library/styles/less/login_box.less */
.form .register-form,
.login-type .register-form {
  display: none;
}
/* line 164, app/library/styles/less/login_box.less */
.form .two-column-panel,
.login-type .two-column-panel {
  display: flex;
  flex-direction: row;
}
/* line 168, app/library/styles/less/login_box.less */
.form .two-column-panel .pane,
.login-type .two-column-panel .pane {
  width: 50%;
  margin: 0.5em;
}
/* line 176, app/library/styles/less/login_box.less */
.wide-form {
  max-width: unset;
}
/* line 181, app/library/styles/less/login_box.less */
.error-message {
  color: red;
  margin-top: 0px;
}
/* line 187, app/library/styles/less/login_box.less */
.bigger-message {
  color: #6a7087;
  font-size: 10.5pt;
  margin-top: 0px;
}
/* line 193, app/library/styles/less/login_box.less */
.PasswordWarning {
  font-size: 11;
  color: red;
  margin-top: -4px;
  margin-bottom: 10px;
  display: none;
}
/* line 201, app/library/styles/less/login_box.less */
.general-button {
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #11b87b;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
/* line 216, app/library/styles/less/login_box.less */
.general-button:hover {
  background: #376d59;
}
