/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
/* line 5, app/library/styles/less/colorbox.less */
#colorbox,
#cboxOverlay,
#cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
/* line 6, app/library/styles/less/colorbox.less */
#cboxWrapper {
  max-width: none;
}
/* line 7, app/library/styles/less/colorbox.less */
#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}
/* line 8, app/library/styles/less/colorbox.less */
#cboxMiddleLeft,
#cboxBottomLeft {
  clear: left;
}
/* line 9, app/library/styles/less/colorbox.less */
#cboxContent {
  position: relative;
}
/* line 10, app/library/styles/less/colorbox.less */
#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
/* line 11, app/library/styles/less/colorbox.less */
#cboxTitle {
  margin: 0;
}
/* line 12, app/library/styles/less/colorbox.less */
#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* line 13, app/library/styles/less/colorbox.less */
#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  cursor: pointer;
}
/* line 14, app/library/styles/less/colorbox.less */
.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}
/* line 15, app/library/styles/less/colorbox.less */
.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
}
/* line 16, app/library/styles/less/colorbox.less */
#colorbox,
#cboxContent,
#cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}
/* 
    User Style: 
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
/* line 23, app/library/styles/less/colorbox.less */
#cboxOverlay {
  background: url(/images/overlay.png) repeat 0 0;
  opacity: 0.9;
  filter: alpha(opacity = 90);
}
/* line 24, app/library/styles/less/colorbox.less */
#colorbox {
  outline: 0;
}
/* line 25, app/library/styles/less/colorbox.less */
#cboxTopLeft {
  width: 21px;
  height: 21px;
  background: url(/images/controls.png) no-repeat -101px 0;
}
/* line 26, app/library/styles/less/colorbox.less */
#cboxTopRight {
  width: 21px;
  height: 21px;
  background: url(/images/controls.png) no-repeat -130px 0;
}
/* line 27, app/library/styles/less/colorbox.less */
#cboxBottomLeft {
  width: 21px;
  height: 21px;
  background: url(/images/controls.png) no-repeat -101px -29px;
}
/* line 28, app/library/styles/less/colorbox.less */
#cboxBottomRight {
  width: 21px;
  height: 21px;
  background: url(/images/controls.png) no-repeat -130px -29px;
}
/* line 29, app/library/styles/less/colorbox.less */
#cboxMiddleLeft {
  width: 21px;
  background: url(/images/controls.png) left top repeat-y;
}
/* line 30, app/library/styles/less/colorbox.less */
#cboxMiddleRight {
  width: 21px;
  background: url(/images/controls.png) right top repeat-y;
}
/* line 31, app/library/styles/less/colorbox.less */
#cboxTopCenter {
  height: 21px;
  background: url(/images/border.png) 0 0 repeat-x;
}
/* line 32, app/library/styles/less/colorbox.less */
#cboxBottomCenter {
  height: 21px;
  background: url(/images/border.png) 0 -29px repeat-x;
}
/* line 33, app/library/styles/less/colorbox.less */
#cboxContent {
  background: #fff;
  overflow: hidden;
}
/* line 34, app/library/styles/less/colorbox.less */
.cboxIframe {
  background: #fff;
}
/* line 35, app/library/styles/less/colorbox.less */
#cboxError {
  padding: 50px;
  border: 1px solid #ccc;
}
/* line 36, app/library/styles/less/colorbox.less */
#cboxLoadedContent {
  margin-bottom: 28px;
}
/* line 37, app/library/styles/less/colorbox.less */
#cboxTitle {
  position: absolute;
  bottom: 4px;
  left: 0;
  text-align: center;
  width: 100%;
  color: #949494;
}
/* line 38, app/library/styles/less/colorbox.less */
#cboxCurrent {
  position: absolute;
  bottom: 4px;
  left: 58px;
  color: #949494;
}
/* line 39, app/library/styles/less/colorbox.less */
#cboxLoadingOverlay {
  background: url(/images/loading_background.png) no-repeat center center;
}
/* line 40, app/library/styles/less/colorbox.less */
#cboxLoadingGraphic {
  background: url(/images/loading.gif) no-repeat center center;
}
/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
/* line 43, app/library/styles/less/colorbox.less */
#cboxPrevious,
#cboxNext,
#cboxSlideshow,
#cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  width: auto;
  background: none;
}
/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
/* line 46, app/library/styles/less/colorbox.less */
#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
  outline: 0;
}
/* line 48, app/library/styles/less/colorbox.less */
#cboxSlideshow {
  position: absolute;
  bottom: 4px;
  right: 30px;
  color: #0092ef;
}
/* line 49, app/library/styles/less/colorbox.less */
#cboxPrevious {
  position: absolute;
  bottom: 0;
  left: 0;
  background: url(/images/controls.png) no-repeat -75px 0;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
}
/* line 50, app/library/styles/less/colorbox.less */
#cboxPrevious:hover {
  background-position: -75px -25px;
}
/* line 51, app/library/styles/less/colorbox.less */
#cboxNext {
  position: absolute;
  bottom: 0;
  left: 27px;
  background: url(/images/controls.png) no-repeat -50px 0;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
}
/* line 52, app/library/styles/less/colorbox.less */
#cboxNext:hover {
  background-position: -50px -25px;
}
/* line 53, app/library/styles/less/colorbox.less */
#cboxClose {
  position: absolute;
  top: 0;
  left: 0;
  background: url(/images/controls.png) no-repeat -25px 0;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
}
/* line 54, app/library/styles/less/colorbox.less */
#cboxClose:hover {
  background-position: -25px -25px;
}
/*
  The following fixes a problem where IE7 and IE8 replace a PNG's alpha transparency with a black fill
  when an alpha filter (opacity change) is set on the element or ancestor element.  This style is not applied to or needed in IE9.
  See: http://jacklmoore.com/notes/ie-transparency-problems/
*/
/* line 61, app/library/styles/less/colorbox.less */
.cboxIE #cboxTopLeft,
.cboxIE #cboxTopCenter,
.cboxIE #cboxTopRight,
.cboxIE #cboxBottomLeft,
.cboxIE #cboxBottomCenter,
.cboxIE #cboxBottomRight,
.cboxIE #cboxMiddleLeft,
.cboxIE #cboxMiddleRight {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF, FF, endColorstr=#00FFFFFF, FF);
}
