/* standard values used across application */
/* palette of colours from style guide */
/* heights of position:fixed elements that we need to add spacer divs for for desktop screen width */
/* heights of position:fixed elements that we need to add spacer divs for for iPhone 6s screen width in landscape mode */
/* line 4, app/library/styles/less/browser.less */
.interlinear-wordbox {
  border-style: dotted;
  border-width: 1px;
  border-color: #E0E0E0;
  height: 172px;
  top: 0;
  padding: 2px;
  margin: 1px;
  padding-top: 4px;
  display: inline-block;
  font-size: 21px;
  text-align: center;
  font-style: normal;
  direction: ltr;
  line-height: 130%;
}
/* line 25, app/library/styles/less/browser.less */
.interlinear-wordbox:hover {
  background-color: #f5f5dd;
}
/* line 30, app/library/styles/less/browser.less */
.interlinear-wordbox-qinitials {
  height: auto;
}
/* line 35, app/library/styles/less/browser.less */
.interlinear-text {
  font-size: 14px;
}
/* line 38, app/library/styles/less/browser.less */
.interlinear-text .morphology {
  font-size: 11.2px;
  color: #689862;
}
/* line 45, app/library/styles/less/browser.less */
.interlinear-morphology {
  color: #689862;
}
/* line 50, app/library/styles/less/browser.less */
.interlinear-tag-area {
  clear: both;
  float: right;
  font-size: 14px;
  line-height: 150%;
}
/* line 57, app/library/styles/less/browser.less */
.tag-area-no-transliteration {
  float: right;
}
/* line 61, app/library/styles/less/browser.less */
.table-cell-interlinear-mode {
  width: 100%;
}
/* line 65, app/library/styles/less/browser.less */
.interlinear-chrome-fix {
  text-align: right;
}
