/* standard values used across application */
/* palette of colours from style guide */
/* heights of position:fixed elements that we need to add spacer divs for for desktop screen width */
/* heights of position:fixed elements that we need to add spacer divs for for iPhone 6s screen width in landscape mode */
/* line 5, app/library/styles/less/admin.less */
table.dataTable .hide-column {
  display: none;
}
/* line 9, app/library/styles/less/admin.less */
table.dataTable.log-statistics tbody tr td {
  text-align: right;
}
/* line 12, app/library/styles/less/admin.less */
table.dataTable.log-statistics tbody tr td .expired,
table.dataTable.log-statistics tbody tr td.expired {
  color: red;
}
/* line 17, app/library/styles/less/admin.less */
table.dataTable.log-statistics tbody tr td .expiring,
table.dataTable.log-statistics tbody tr td.expiring {
  color: orange;
}
/* line 22, app/library/styles/less/admin.less */
table.dataTable.log-statistics tbody tr td:first-child,
table.dataTable.log-statistics thead tr th:first-child {
  text-align: left;
}
