/* standard values used across application */
/* palette of colours from style guide */
/* heights of position:fixed elements that we need to add spacer divs for for desktop screen width */
/* heights of position:fixed elements that we need to add spacer divs for for iPhone 6s screen width in landscape mode */
/* line 3, app/library/styles/less/auth.less */
.localhost-password-reset {
  background-color: #689862;
  margin: 0.5em;
  padding: 0.5em;
  border: 1px solid #2e1f07;
  box-shadow: 0.5em 0.25em 0.25em #e6e1dc;
}
/* line 11, app/library/styles/less/auth.less */
#next-steps {
  display: flex;
  justify-content: center;
}
/* line 14, app/library/styles/less/auth.less */
#next-steps .step {
  margin: 0.5em;
}
/* line 19, app/library/styles/less/auth.less */
#reset-password-link {
  position: relative;
  top: -40px;
  right: -103px;
  text-decoration: none;
  color: rgba(0, 0, 255, 0.62);
}
