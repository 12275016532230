/* standard values used across application */
/* palette of colours from style guide */
/* heights of position:fixed elements that we need to add spacer divs for for desktop screen width */
/* heights of position:fixed elements that we need to add spacer divs for for iPhone 6s screen width in landscape mode */
/* line 2, app/library/styles/less/dialogue_boxes.less */
.dialog-box {
  background-color: white;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  z-index: 1;
  display: none;
  margin: auto auto;
  left: 0;
  right: 0;
  position: absolute;
  padding: 8px;
  text-align: center;
  width: 500px;
}
/* line 17, app/library/styles/less/dialogue_boxes.less */
.dialog-box h3 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: Cabin, Arial, Helvetica, sans-serif;
  font-size: 16.8px;
}
/* line 23, app/library/styles/less/dialogue_boxes.less */
.dialog-box button {
  margin: 4px;
  padding: 4px;
}
/* line 27, app/library/styles/less/dialogue_boxes.less */
.dialog-box .form {
  padding: 1.5em 3em;
  margin: 1.5em;
  max-width: unset;
}
/* line 35, app/library/styles/less/dialogue_boxes.less */
#PASSWORD_PANEL,
#RESET_PASSWORD_PANEL,
#DELETE_USER_PANEL {
  display: block;
}
/* line 41, app/library/styles/less/dialogue_boxes.less */
#update-user-dialog {
  display: block;
}
/* line 45, app/library/styles/less/dialogue_boxes.less */
#FORMULAIC_PANEL {
  background-color: #e0e0e0;
  margin-left: 0px;
  width: 410px;
}
/* line 51, app/library/styles/less/dialogue_boxes.less */
#ANALYTICS_PANEL {
  background-color: #e0e0e0;
  width: 270px;
  position: absolute;
  margin-right: 0px;
}
/* line 58, app/library/styles/less/dialogue_boxes.less */
#TAGS_PANEL {
  background-color: #e0e0e0;
  width: 270px;
  position: absolute;
  margin-right: 50px;
}
/* line 65, app/library/styles/less/dialogue_boxes.less */
.analytics-button {
  width: 267px;
  font-size: 12px;
  margin: 4px;
}
/* line 71, app/library/styles/less/dialogue_boxes.less */
.tag-popup-panel {
  overflow: auto;
  max-height: 200px;
  width: 270px;
  border: 1px solid black;
  margin-top: 10px;
  margin-bottom: 5px;
}
/* line 80, app/library/styles/less/dialogue_boxes.less */
.user-detail-pane-table-header {
  width: 880px;
  border: 1px solid black;
}
/* line 85, app/library/styles/less/dialogue_boxes.less */
.user-detail-pane-table-content {
  overflow: auto;
  max-height: 600px;
  width: 880px;
  border: 1px solid black;
  margin-bottom: 5px;
}
