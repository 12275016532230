/* Used by verse_browser.php and verse_browser.php to put the search/lookup term in a nice pill shape */
/* line 3, app/library/styles/less/menubar.less */
.pill-button {
  border: 2px solid #000090;
  border-radius: 15px;
  padding: 1px 5px !important;
  text-align: center;
  margin-right: 0px;
  margin-left: 0px;
  background-color: #000090;
  color: white;
}
/* line 14, app/library/styles/less/menubar.less */
#BOOKMARK_POPUP {
  background-color: #ffffff;
  width: 450px;
  height: 190px;
  position: relative;
  left: -50%;
  top: 20px;
  padding: 0px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.5);
  border-style: solid;
  border-width: 10px;
  border-color: black;
  display: none;
  margin: auto;
  z-index: 1;
}
